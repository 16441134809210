import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BanksSelectComponent } from './banksSelect.components';

@NgModule({
  imports: [ CommonModule ],
  declarations: [ BanksSelectComponent ],
  exports: [ BanksSelectComponent ]
})

export class BanksSelectModule {}
