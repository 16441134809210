import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditableInputComponent } from './editableInput.components';

@NgModule({
  imports: [ CommonModule ],
  declarations: [ EditableInputComponent ],
  exports: [ EditableInputComponent ]
})

export class EditableInputModule {}
