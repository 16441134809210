import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router'
import {AuthService} from '../../auth/services/auth.service'
import {Observable, of} from 'rxjs'
import {Injectable} from '@angular/core'
import {PersistanceService} from 'src/app/shared/services/persistance.service'
import {catchError, map} from 'rxjs/operators'

@Injectable()
export class AuthGuardAnonymous implements CanActivate {
  private data: object
  private accessToken: string
  constructor(private authService: AuthService, private router: Router, private persistanceService: PersistanceService) {
    this.accessToken = this.persistanceService.get('accessToken')
  };

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isAuth().pipe(map((response: { authenticated: boolean}) => {
      if (response.authenticated) {
        return true;
      }
      console.log('here1')
      this.router.navigate(['/login']);
      return false;
    }), catchError((error) => {
      console.log('here2')
      this.router.navigate(['/login']);
      return of(false);
    }));
  }
}

