import {Component, Input, OnInit} from '@angular/core'

import {LeadService} from 'src/app/lead/services/lead.service'

@Component({
  selector: 'editable-textarea-cmp',
  templateUrl: 'editableTextarea.component.html',
  styleUrls: [ './editableTextarea.component.scss' ]
})

export class EditableTextareaComponent implements OnInit{
  @Input() inputValue: string;
  @Input() inputClass: string;
  @Input() isNumber: boolean;
  @Input() inputDisabled: boolean | "";
  @Input() callbackFunction: (val: string, field: string) => void;
  @Input() public field: string;
  oldValue: string
  isActive: ""
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  constructor() {

  }

  inputClick($event): void{
    this.inputClass += " active";
    this.oldValue = $event.target.value
  }

  inputChange($event): void{
    this.inputValue = $event.target.value
  }

  inputFocus($event): void{
    if(this.isNumber){
      $event.target.value = $event.target.value.replace(".00", "").replace(/[^0-9]/g, '');
    }
  }

  inputBlur($event): void{
    if(this.isNumber)$event.target.value = this.formatter.format($event.target.value)
  }

  clickOkFunction($event): void{
    this.inputClass = this.inputClass.replace("active",'');
    this.callbackFunction($event, this.field)
  }

  clickCancelFunction(): void{
    this.inputClass = this.inputClass.replace("active",'');
    this.inputValue = this.oldValue
  }

  ngOnInit() {

  }

}
