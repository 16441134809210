import {Component, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core'

import {
  leadsFeatureSelector,
  getLeadsSelector
} from 'src/app/openLeads/store/selectors'
import {Store, select} from '@ngrx/store'
import {Subscription, Observer, Observable} from 'rxjs'
import {LeadsInterface} from 'src/app/shared/types/leads.interface'

import {getOpenLeadsAction} from 'src/app/openLeads/store/actions/getOpenLeads.action'

import { filter } from 'rxjs/operators'
import { Router } from '@angular/router'

declare interface TableData {
  headerRow: string[];
  dataRows: {}[];
}


@Component({
  selector: 'open-leads-cmp',
  templateUrl: 'openLeads.component.html',
  styleUrls: ['./openLeads.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class OpenLeadsComponent implements OnInit, OnDestroy{
  public tableData: any;
  public tableDataJs: any;
  leads: LeadsInterface
  leadSubscription: Subscription
  @ViewChild('dataTable', {static: true}) table;

  dtOptions: {};

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    let self = this;
    this.tableData = $(this.table.nativeElement);
    this.dtOptions = {
      columnDefs: [{
        "defaultContent": "-",
        "targets": "_all"
      }],
      rowReorder: {
        selector: 'td:nth-child(2)'
      },
      responsive: true,
      columns: [
        {title: 'ID', data: 'ID'},
        // {title: 'USER_ID', data: 'USER_ID'},
        {title: 'Name', data: 'NAME'},
        {title: 'Created', data: 'DATE_CREATE'},
        // {title: 'Updated', data: 'DATE_UPDATE'},
      ]
    };
    this.tableDataJs = this.tableData.DataTable(this.dtOptions);

    this.store.dispatch(getOpenLeadsAction())
    this.leadSubscription = this.store.pipe(select(getLeadsSelector)).subscribe((leads) => {
      this.leads = leads;
      if(leads.leads)this.tableDataJs.rows.add(leads.leads).draw()
      this.tableData.off('click').on('click', 'tr', function () {
        if(window.innerWidth < 600) return;
        let leadID = self.tableDataJs.row( this ).data().ID;
        self.router.navigate(['lead/'+leadID])
      } );
    })
  }




  ngOnDestroy(): void {
    this.leadSubscription.unsubscribe()
  }
}
