import { HttpClient, HttpParams } from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core'


@Component({
  selector: 'plans-select-cmp',
  templateUrl: 'plansSelect.component.html'
})

export class PlansSelectComponent implements OnInit{
  @Input('selectID') selectID = '';
  public Plans: any

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit() {
    const formData = new FormData();
    formData.append('api', "getPlans");
    formData.append('json', "true");


    this.httpClient.post('https://mortgages.ratepal.ca/api/backend.php', formData).subscribe(data => this.Plans = data);
  }

}
