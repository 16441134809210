import {LeadsInterface} from 'src/app/shared/types/leads.interface'
import {createReducer, on, Action} from '@ngrx/store'
import {
  getOpenLeadsAction,
  getOpenLeadsSuccessAction,
  getOpenLeadsFailureAction
} from 'src/app/openLeads/store/actions/getOpenLeads.action'

const initialState: LeadsInterface = {
  leads: null
}

const leadsReducers = createReducer(
  initialState,
  on(
    getOpenLeadsAction,
    (state, action): LeadsInterface => ({
      ...state,
      leads: null
    })
  ),
  on(
    getOpenLeadsSuccessAction,
    (state, action): LeadsInterface => ({
      ...state,
      leads: action.leads
    })
  ),
  on(
    getOpenLeadsFailureAction,
    (state, action): LeadsInterface => ({
      ...state,
      leads: null
    })
  )
)

export function reducers(state: LeadsInterface, action: Action) {
  return leadsReducers(state, action)
}
