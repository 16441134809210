import {Component, EventEmitter, Input, OnInit, Output, OnDestroy, SimpleChanges} from '@angular/core'
import { Subscription } from 'rxjs';
import { sendMessgaeService } from 'src/app/shared/modules/messageBox/services/sendMessgae.service';

@Component({
  selector: 'mc-messageBox',
  templateUrl: './messageBox.component.html',
  styleUrls: ['./messageBox.component.scss']
})
export class MessageBoxComponent implements OnInit {
  messageText: string
  messageClasses: string
  SMSsubscription: Subscription;

  constructor(private sms: sendMessgaeService) {
    this.SMSsubscription = this.sms.getData().subscribe(x => {
      let self = this;
      this.messageText = x.message
      this.messageClasses = x.cls + " showed"
      setTimeout(function (){
        self.messageClasses = self.messageClasses.replace("showed", "")
      }, 2000)
    });
  }

  ngOnInit(): void {
    this.messageClasses = ""
    this.messageText = ""
  }

  ngOnDestroy(): void{

  }

  hideMessage(): void{
    this.messageClasses = this.messageClasses.replace("showed", "")
  }
}
