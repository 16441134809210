import {Injectable} from '@angular/core'
import {HttpClient, HttpErrorResponse} from '@angular/common/http'
import {Observable, throwError} from 'rxjs'
import {catchError, map} from 'rxjs/operators'

import {LeadsInterface} from 'src/app/shared/types/leads.interface'

import {environment} from 'src/environments/environment'
import {PersistanceService} from 'src/app/shared/services/persistance.service'

@Injectable()
export class LeadsService {
  constructor(private http: HttpClient, private persistanceService: PersistanceService) {
  }

  // getLead(response: LeadsInterface): LeadInterface{
  //   console.log("getleadService")
  //   return response
  // }

  getLeads(): Observable<LeadsInterface> {
    console.log("getleads")
    const url = environment.apiUrl + '/get_leads.php'
    const params = {
      user: {
        email: this.persistanceService.get('email'),
        token: this.persistanceService.get('accessToken')
      }
    }
      return this.http.post<LeadsInterface>(url, params).pipe(
        map(resp => resp),
        catchError((error: HttpErrorResponse) => throwError(error))
      )
  }

  getOpenLeads(): Observable<LeadsInterface> {
    console.log("getleads")
    const url = environment.apiUrl + '/get_leads.php'
    const params = {
      user: {
        email: this.persistanceService.get('email'),
        token: this.persistanceService.get('accessToken'),
        open: true
      }
    }
    return this.http.post<LeadsInterface>(url, params).pipe(
      map(resp => resp),
      catchError((error: HttpErrorResponse) => throwError(error))
    )
  }
}
