import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {StoreModule} from '@ngrx/store'
import {EffectsModule} from '@ngrx/effects'

import {RouterModule, Routes} from '@angular/router'


import {reducers} from 'src/app/auth/store/reducers'
import {BackendErrorMessagesModule} from 'src/app/shared/modules/backendErrorMessages/backendErrorMessages.module'

import {GetCurrentUserEffect} from 'src/app/auth/store/effects/getCurrentUser.effect'
import {ProfileComponent} from './components/profile/profile.component'
import {LoginComponent} from '../auth/components/login/login.component'
import {AuthGuardAnonymous} from '../shared/services/authGuardAnonymous.service'
import {ReactiveFormsModule} from '@angular/forms'
import {ProvinceSelect} from '../shared/modules/provinceSelect/povinceSelect.module'

const routes = [
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('profile', reducers),
    BackendErrorMessagesModule,
    ReactiveFormsModule,
    EffectsModule.forFeature([
      GetCurrentUserEffect
    ]),
    CommonModule,
    ProvinceSelect
  ],
  declarations: [ProfileComponent]
})
export class ProfileModule {}
