<div class="{{preloadingClass}}">
<link href="https://fonts.googleapis.com/css?family=Roboto+Slab:400,700" rel="stylesheet">
<link rel="stylesheet" href="https://mortgages.ratepal.ca/css/widget_steps/bootstrap-grid.css">
<link rel="stylesheet" href="https://mortgages.ratepal.ca/css/widget_steps/select2.min.css">
<link rel="stylesheet" href="https://mortgages.ratepal.ca/css/widget_steps/styles.css">

<div id="calcSnippet" class="container loading">
  <h2>Mortgage Application Form Widget</h2>
  <div class="options col-xs-12">
    <div class="row">
      <div class="col-md-3 col-xs-12 title">Select colors:</div>
      <div class="col-md-9 col-xs-12 p0">
        <div class="col-md-4">
          <input id="color1" type="text" class="jscolor colorFirst" value="1D4B70">
        </div>
        <div class="col-md-4">
          <input id="color2" type="text" class="jscolor colorSecond" value="4593C5">
        </div>
        <div class="col-md-4">
          <input id="color3" type="text" class="jscolor colorThird" value="1D4B70">
        </div>
      </div>
    </div>
    <div class="row"  style="position: relative;padding-bottom: 80px;">
      <div class="col-md-3 col-xs-12 title">Pesonal widget link:</div>
      <div class="col-md-9 col-xs-12">
        <a style="color: white" target="_blank" id="personalLink" href="#"></a>
        <textarea id="calcSnippetTextLink" cols="30" style="height: auto;"></textarea>
      </div>
      <button id="calcCopyToClipboardLink" class="col-md-3 col-xs-12">Copy to clipboard</button>
    </div>
    <div class="row">
      <div class="col-md-3 col-xs-12 title">HTML Snippet:</div>
      <div class="col-md-9 col-xs-12">
        <textarea id="calcSnippetText" cols="30"></textarea>
      </div>
      <button id="calcCopyToClipboard" class="col-md-3 col-xs-12">Copy to clipboard</button>
    </div>
  </div>
</div>

<div style="margin-top: 30px" class='calcDataWrapper' style='display: none'></div>
<style>

</style>
</div>
