import {Component, Inject, OnInit, Renderer2} from '@angular/core'
import {DOCUMENT} from '@angular/common'

@Component({
  selector: 'land-transfer-tax-calculator-cmp',
  templateUrl: 'land-transfer-tax-calculator.component.html'
})

export class LandTransferCalculatorComponent implements OnInit{
  public preloadingClass: string;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2
  ) {

  }

  ngOnInit(){
    const time = Date.now();
    let self = this;
    this.preloadingClass = "preloader";
    const renderer2 = this.renderer2;

    const srcScript = this.renderer2.createElement('script');
    srcScript.type = 'text/javascript';
    srcScript.text = `
      (function() {
        jscolor.installByClassName("jscolor")
      }());
    `;


    // const textScript = this.renderer2.createElement('script');
    // textScript.src = 'https://mortgages.ratepal.ca/api/allScripts.js';
    // this.renderer2.appendChild(this.document.body, textScript);

    // const textScript1 = this.renderer2.createElement('script');
    // textScript1.src = 'https://mortgages.ratepal.ca/js/widget_tax/jquery-3.3.1.min.js';
    // this.renderer2.appendChild(this.document.body, textScript1);

    const textScript11 = this.renderer2.createElement('script');
    textScript11.src = 'https://mortgages.ratepal.ca/js/widget_tax/select2.full.min.js';

    const textScript12 = this.renderer2.createElement('script');
    textScript12.src = 'https://mortgages.ratepal.ca/js/widget_tax/jscolor.js';

    const textScript13 = this.renderer2.createElement('script');
    textScript13.src = 'https://mortgages.ratepal.ca/js/widget_tax/script_broker.js?v='+time;

    // const textScript2 = this.renderer2.createElement('script');
    // textScript2.src = 'https://mortgages.ratepal.ca/api/mortgage-rates-calculator/load_brokers.js';
    setTimeout(function(){
      renderer2.appendChild(this.document.body, textScript11);
      // renderer2.appendChild(this.document.body, textScript12);
    }, 0)
    setTimeout(function(){
      // renderer2.appendChild(this.document.body, textScript11);
      renderer2.appendChild(this.document.body, textScript12);
      renderer2.appendChild(this.document.body, srcScript);
    }, 500)

    setTimeout(function(){
      renderer2.appendChild(this.document.body, textScript13);
      // renderer2.appendChild(this.document.body, textScript2);
    }, 2000)

    setTimeout(function (){
      self.preloadingClass = "";
    }, 1000)

  }
}
