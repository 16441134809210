
<div>
  <form class="row" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="col-md-4">
    <div id="usedCard" class="card card-user unloaded" (load)='usedCardLoad($event)'>
      <div class="image">
        <img src="assets/img/damir-bosnjak.jpg" alt="...">
      </div>
      <div class="card-body">
        <div class="author">
          <a>
            <div class="avatar border-gray" [ngStyle]="{ 'background-image': 'url(' + imageSrc + ')'}" alt="..." (click)="selectFile.click()"></div>
            <input type="file" hidden [src]="(currentUser$ | async).image" (change)="getImage($event)" #selectFile accept=".png, .jpg, .jpeg">
            <h5 class="title">
              {{(currentUser$ | async).firstname + " " + (currentUser$ | async).lastname}}
            </h5>
          </a>
          <p class="description">
            {{(currentUser$ | async).brokeragename}}
          </p>
        </div>
        <p class="description text-center">

        </p>
      </div>
<!--      <div class="card-footer">-->
<!--        <hr>-->
<!--        <div class="button-container">-->
<!--          <div class="row">-->
<!--            <div class="col-lg-3 col-md-6 col-6 ml-auto">-->
<!--              <h5>12-->
<!--                <br>-->
<!--                <small>Files</small>-->
<!--              </h5>-->
<!--            </div>-->
<!--            <div class="col-lg-4 col-md-6 col-6 ml-auto mr-auto">-->
<!--              <h5>2GB-->
<!--                <br>-->
<!--                <small>Used</small>-->
<!--              </h5>-->
<!--            </div>-->
<!--            <div class="col-lg-3 mr-auto">-->
<!--              <h5>24,6$-->
<!--                <br>-->
<!--                <small>Spent</small>-->
<!--              </h5>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div id="teamCard" class="card unloaded">
      <div class="card-header">
        <h4 class="card-title">Team Members</h4>
      </div>
      <div class="card-body">
        <ul class="list-unstyled team-members">
          <li>
            <div class="row">
              <div class="col-md-2 col-2">
                <div class="avatar">
                  <img src="assets/img/faces/ayo-ogunseinde-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                </div>
              </div>
              <div class="col-md-7 col-7">
                DJ Khaled
                <br />
                <span class="text-muted">
                </span>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-md-2 col-2">
                <div class="avatar">
                  <img src="assets/img/faces/joe-gardner-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                </div>
              </div>
              <div class="col-md-7 col-7">
                Creative Tim
                <br />
                <span class="text-success">
                </span>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <div class="col-md-2 col-2">
                <div class="avatar">
                  <img src="assets/img/faces/clem-onojeghuo-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                </div>
              </div>
              <div class="col-ms-7 col-7">
                Flume
                <br />
                <span class="text-danger">
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-md-8">
    <div id="infoCard" class="card card-user unloaded">
      <div class="card-header">
        <h5 class="card-title">Edit Profile</h5>
      </div>
      <div class="card-body">
          <div class="row">
            <div class="col-md-6 pr-1">
              <div class="form-group">
                <label>Brokerage Name</label>
                <input type="text" class="form-control" placeholder="Brokerage Name" [value]="(currentUser$ | async).brokeragename" formControlName="brokeragename">
              </div>
            </div>
            <div class="col-md-6 pl-1">
              <div class="form-group">
                <label>License number</label>
                <input type="text" class="form-control" placeholder="License number" [value]="(currentUser$ | async).licensenumber" formControlName="licensenumber"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Brokerage Address</label>
                <textarea type="text" class="form-control" placeholder="Brokerage Address" id='' cols='30' rows='10' [value]="(currentUser$ | async).brokerageaddress" formControlName="brokerageaddress"></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 pr-1">
              <div class="form-group">
                <label>First Name</label>
                <input type="text" class="form-control" placeholder="First Name"  [value]="(currentUser$ | async).firstname" formControlName="firstname"/>
              </div>
            </div>
            <div class="col-md-6 pl-1">
              <div class="form-group">
                <label>Middle Name</label>
                <input type="text" class="form-control" placeholder="Middle Name" [value]="(currentUser$ | async).middlename ? (currentUser$ | async).middlename : ''" formControlName="middlename"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 pr-1">
              <div class="form-group">
                <label>Last Name</label>
                <input type="text" class="form-control" placeholder="Last Name" [value]="(currentUser$ | async).lastname" formControlName="lastname"/>
              </div>
            </div>
            <div class="col-md-6 pl-1">
              <div class="form-group">
                <label>Province</label>
                <select class="custom-select" formControlName="province">
                  <option *ngFor="let province of Provinces" [selected]="(currentUser$ | async).province == province" [value]="province">{{province}}</option>
                </select>
<!--                <province-select [userProvince]="(currentUser$ | async).province" ></province-select>-->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 pr-1">
              <div class="form-group">
                <label>Phone</label>
                <input type="text" class="form-control" placeholder="Phone" [value]="(currentUser$ | async).phone" formControlName="phone"/>
              </div>
            </div>
            <div class="col-md-6 pl-1">
              <div class="form-group">
                <label>Email</label>
                <input type="text" class="form-control" placeholder="Email" [value]="(currentUser$ | async).email" formControlName="email"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 pr-1">
              <div class="form-group">
                <label>Password</label>
                <input type="password" class="form-control" placeholder="Password" formControlName="password"/>
              </div>
            </div>
            <div class="col-md-6 pl-1">
              <div class="form-group">
                <label>Confirm Password</label>
                <input type="password" class="form-control" placeholder="Confirm Password" formControlName="passwordconfirm"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="update ml-auto mr-auto">
              <button type="submit" class="btn btn-primary btn-round" [disabled]="form.invalid || (isSubmitting$ | async)">Update Profile</button>
            </div>
          </div>
          <button class="btn btn-outline-danger pull-right" (click)="logout()" type="text">
            Click here to logout
          </button>
      </div>
    </div>
  </div>
  </form>
</div>

