<select *ngIf="multiple" id="{{selectID}}" multiple>
  <option selected *ngFor="let bank of Banks" value="{{bank.ID}}" >{{bank.BANK}}</option>
</select>
<select *ngIf="!multiple && !hideAll" id="{{selectID}}">
  <option value="All">All</option>
  <option *ngFor="let bank of Banks" value="{{bank.ID}}" >{{bank.BANK}}</option>
</select>
<select *ngIf="!multiple && hideAll" id="{{selectID}}">
  <option *ngFor="let bank of Banks" value="{{bank.ID}}" >{{bank.BANK}}</option>
</select>
