import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core'
import {
  currentUserSelector
} from 'src/app/auth/store/selectors'
import {Store, select} from '@ngrx/store'
import {Subscription, Observer, Observable} from 'rxjs'
import {CurrentUserInterface} from '../../../shared/types/currentUser.interface'
import {logoutAction} from 'src/app/auth/store/actions/sync.action'
import {FormBuilder, FormGroup} from '@angular/forms'
import {BackendErrorsInterface} from 'src/app/shared/types/backendErrors.interface'
import {
  isSubmittingSelector,
  validationErrorsSelector
} from '../../store/selectors'
import {filter} from 'rxjs/operators'
import {CurrentUserInputInterface} from 'src/app/shared/types/currentUserInput.interface'
import {updateCurrentUserAction} from 'src/app/auth/store/actions/updateCurrentUser.action'


@Component({
  selector: 'settings-cmp',
  templateUrl: 'settings.component.html'
})

export class SettingsComponent implements OnInit, OnDestroy{
  form: FormGroup
  currentUser$: Observable<CurrentUserInterface | null>
  currentUser: CurrentUserInterface
  currentUserSubscription: Subscription
  isSubmitting$: Observable<boolean>
  backendErrors$: Observable<BackendErrorsInterface | null>
  imageSrc: any ="assets/img/default-avatar.png"

  constructor(private fb: FormBuilder, private store: Store) {}

  ngOnInit(): void {

    this.initializeValues()
    this.initializeListeners()
    this.currentUser$ = this.store.pipe(select(currentUserSelector))
  }

  usedCardLoad($event): void{
    alert($event)
  }



  ngAfterViewInit(): void{
    var usedCardElement = document.getElementById("usedCard");
    var teamCardElement = document.getElementById("teamCard");
    var infoCardElement = document.getElementById("infoCard");
    setTimeout(function (){
      usedCardElement.classList.remove("unloaded");
    }, 100)
    setTimeout(function (){
      teamCardElement.classList.remove("unloaded");
    }, 300)
    setTimeout(function (){
      infoCardElement.classList.remove("unloaded");
    }, 500)
  }


  ngOnDestroy(): void {
    this.currentUserSubscription.unsubscribe()
  }
  initializeValues(): void {
    this.isSubmitting$ = this.store.pipe(select(isSubmittingSelector))
    this.backendErrors$ = this.store.pipe(select(validationErrorsSelector))
  }

  initializeListeners(): void {
    this.currentUserSubscription = this.store
      .pipe(select(currentUserSelector), filter(Boolean))
      .subscribe((currentUser: CurrentUserInterface) => {
        this.currentUser = currentUser
        this.imageSrc = this.currentUser.image
        this.initializeForm()
      })
  }

  getImage(ev) {
    var self = this;
    var fReader = new FileReader();
    fReader.readAsDataURL(ev.target.files[0]);
    fReader.onloadend = function(event){
      var img = document.getElementById("yourImgTag");
      self.imageSrc = event.target.result;
      self.form.value.image = self.imageSrc
    }
  }

  initializeForm(): void {
    this.form = this.fb.group({
      brokeragename: this.currentUser.brokeragename,
      brokerageaddress: this.currentUser.brokerageaddress,
      licensenumber: this.currentUser.licensenumber,
      province: this.currentUser.province,
      firstname: this.currentUser.firstname,
      middlename: this.currentUser.middlename,
      lastname: this.currentUser.lastname,
      phone: this.currentUser.phone,
      email: this.currentUser.email,
      custom_app: this.currentUser.custom_app,
      password: this.currentUser.password,
      passwordconfirm: this.currentUser.passwordconfirm,
    })
  }

  logout(): void {
    this.store.dispatch(logoutAction())
  }

  onSubmit() {
    const currentUserInput: CurrentUserInputInterface = {
      ...this.currentUser,
      ...this.form.value
    }
    console.log('here')
    this.store.dispatch(updateCurrentUserAction({currentUserInput}))
  }

}
