<div class="auth-page">
  <div class="container page">
    <div class="row">
      <div class="col-md-6 offset-md-3 col-xs-12">
        <h1 class="text-xs-center">Sign In</h1>
        <p class="text-xs-center">
          <a (click)="needAccountClick($event)" [routerLink]="['/register']">Need an account?</a>
        </p>
        <mc-backend-error-messages
          *ngIf="backendErrors$ | async"
          [backendErrors]="backendErrors$ | async"
        ></mc-backend-error-messages>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <fieldset>
            <fieldset class="form-group">
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Email"
                formControlName="email"
              />
            </fieldset>
            <fieldset class="form-group">
              <input
                type="password"
                class="form-control form-control-lg"
                placeholder="Password"
                formControlName="password"
              />
            </fieldset>
            <button
              class="btn btn-lg btn-primary pull-xs-right"
              type="submit"
              [disabled]="isSubmitting$ | async"
            >
              Sign in
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>
