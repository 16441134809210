import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {StoreModule} from '@ngrx/store'
import {EffectsModule} from '@ngrx/effects'

import { DataTablesModule } from "angular-datatables";

import {RouterModule, Routes} from '@angular/router'

import {LeadService} from 'src/app/lead/services/lead.service'

import {reducers} from 'src/app/lead/store/reducers'
import {GetLeadEffect} from 'src/app/lead/store/effects/getLead.effect'

import {LeadComponent} from './components/lead/lead.component'

import {EditableInputModule} from 'src/app/shared/modules/editableInput/editableInput.module'
import {EditableTextareaModule} from 'src/app/shared/modules/editableTextarea/editableTextarea.module'
import {LeadStatusSelectModule} from 'src/app/shared/modules/leadStatusSelect/leadStatusSelect.module'
import { NgSelect2Module } from 'ng-select2';
import { Select2Module } from 'ng-select2-component';

import {DpDatePickerModule} from 'ng2-date-picker';

const routes = [
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    DataTablesModule,
    EditableInputModule,
    EditableTextareaModule,
    LeadStatusSelectModule,
    StoreModule.forFeature('lead', reducers),
    EffectsModule.forFeature([
      GetLeadEffect
    ]),
    Select2Module
  ],
  declarations: [LeadComponent],
  providers: [LeadService, GetLeadEffect]
})
export class LeadModule {}
