<div id="widgets" class="row">
  <div class='col-lg-4 col-md-6 col-12 pb-3'>
    <div class="card unloaded" [routerLink]="['/mortgage-application']">
      <div class="title">Mortgage Application</div>
      <div class="info pt-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusantium alias cupiditate, ducimus eius et eum facilis fuga iste mollitia nihil reiciendis soluta tenetur vitae voluptates? Laborum maiores quis voluptates?</div>
    </div>
  </div>

  <div class='col-lg-4 col-md-6 col-12 pb-3'>
    <div class="card unloaded" [routerLink]="['/canadian-mortgage-calculator']">
      <div class="title">Canadian Mortgage Calculator</div>
      <div class="info pt-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusantium alias cupiditate, ducimus eius et eum facilis fuga iste mollitia nihil reiciendis soluta tenetur vitae voluptates? Laborum maiores quis voluptates?</div>
    </div>
  </div>

  <div class='col-lg-4 col-md-6 col-12 pb-3'>
    <div class="card unloaded" [routerLink]="['/current-mortgage-calculator']">
      <div class="title">Current Mortgage Calculator</div>
      <div class="info pt-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusantium alias cupiditate, ducimus eius et eum facilis fuga iste mollitia nihil reiciendis soluta tenetur vitae voluptates? Laborum maiores quis voluptates?</div>
    </div>
  </div>

  <div class='col-lg-4 col-md-6 col-12 pb-3'>
    <div class="card unloaded" [routerLink]="['/land-transfer-calculator']">
      <div class="title">Land Transfer Calculator</div>
      <div class="info pt-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusantium alias cupiditate, ducimus eius et eum facilis fuga iste mollitia nihil reiciendis soluta tenetur vitae voluptates? Laborum maiores quis voluptates?</div>
    </div>
  </div>

  <div class='col-lg-4 col-md-6 col-12 pb-3'>
    <div class="card unloaded" [routerLink]="['/mortgage-rates-calculator']">
      <div class="title">Mortgage Rates Calculator</div>
      <div class="info pt-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusantium alias cupiditate, ducimus eius et eum facilis fuga iste mollitia nihil reiciendis soluta tenetur vitae voluptates? Laborum maiores quis voluptates?</div>
    </div>
  </div>

  <div class='col-lg-4 col-md-6 col-12 pb-3'>
    <div class="card unloaded" [routerLink]="['/mortgage-affordability-calculator']">
      <div class="title">Mortgage Affordability Calculator</div>
      <div class="info pt-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusantium alias cupiditate, ducimus eius et eum facilis fuga iste mollitia nihil reiciendis soluta tenetur vitae voluptates? Laborum maiores quis voluptates?</div>
    </div>
  </div>

  <div class='col-lg-4 col-md-6 col-12 pb-3'>
    <div class="card unloaded" [routerLink]="['/prequalification-application']">
      <div class="title">Prequalification Application</div>
      <div class="info pt-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusantium alias cupiditate, ducimus eius et eum facilis fuga iste mollitia nihil reiciendis soluta tenetur vitae voluptates? Laborum maiores quis voluptates?</div>
    </div>
  </div>

  <div class='col-lg-4 col-md-6 col-12 pb-3'>
    <div class="card unloaded" [routerLink]="['/rates-by-lender-calculator']">
      <div class="title">Rates By Lender</div>
      <div class="info pt-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusantium alias cupiditate, ducimus eius et eum facilis fuga iste mollitia nihil reiciendis soluta tenetur vitae voluptates? Laborum maiores quis voluptates?</div>
    </div>
  </div>

  <div class='col-lg-4 col-md-6 col-12 pb-3'>
    <div class="card unloaded" [routerLink]="['/steps-form-calculator']">
      <div class="title">Mortgage Quote Form</div>
      <div class="info pt-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusantium alias cupiditate, ducimus eius et eum facilis fuga iste mollitia nihil reiciendis soluta tenetur vitae voluptates? Laborum maiores quis voluptates?</div>
    </div>
  </div>

</div>
