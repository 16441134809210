<div class="{{preloadingClass}}">
<link href="https://fonts.googleapis.com/css?family=Roboto+Slab:400,700" rel="stylesheet">
<link rel="stylesheet" href="https://mortgages.ratepal.ca/css/widget_lowest/bootstrap-grid.css">
<link rel="stylesheet" href="https://mortgages.ratepal.ca/css/widget_lowest/select2.min.css">
<link rel="stylesheet" href="https://mortgages.ratepal.ca/css/widget_lowest/styles.css">

<div id="calcSnippet" class="container">
  <h2>Current Mortgage Rates Widget</h2>
  <div class="options col-xs-12">
    <div class="row">
      <div class="col-md-3 col-xs-12 title">Select Rate types:</div>
      <div class="col-md-9 col-xs-12">
        <plans-select-cmp selectID="calcPlans" multiple="multiple"></plans-select-cmp>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-xs-12 title">Select Terms:</div>
      <div class="col-md-9 col-xs-12">
        <select id="calcTerms" multiple>
          <option value="1" selected>1 year</option>
          <option value="2" selected>2 years</option>
          <option value="3" selected>3 years</option>
          <option value="4" selected>4 years</option>
          <option value="5" selected>5 years</option>
          <option value="6" selected>6 years</option>
          <option value="7" selected>7 years</option>
          <option value="8" selected>8 years</option>
          <option value="9" selected>9 years</option>
          <option value="10" selected>10 years</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-xs-12 title">Select province:</div>
      <div class="col-md-9 col-xs-12">
        <select id="calcProvince">
          <option value="-1">All</option>
          <option value="0">Alberta</option>
          <option value="1">British Columbia</option>
          <option value="2">Manitoba</option>
          <option value="3">New Brunswick</option>
          <option value="4">Newfoundland</option>
          <option value="5">Northwest Territories</option>
          <option value="6">Nova Scotia</option>
          <option value="7">Nunavut</option>
          <option value="8">Ontario</option>
          <option value="9">Prince Edward Island</option>
          <option value="10">Quebec</option>
          <option value="11">Saskatchewan</option>
          <option value="12">Yukon</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-xs-12 title">Select colors:</div>
      <div class="col-md-9 col-xs-12 p0">
        <div class="col-md-4">
          <input id="color1" type="text" class="jscolor colorFirst" value="1D4B70">
        </div>
        <div class="col-md-4">
          <input id="color2" type="text" class="jscolor colorSecond" value="4593C5">
        </div>
        <div class="col-md-4">
          <input id="color3" type="text" class="jscolor colorThird" value="1D4B70">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-xs-12 title">HTML Snippet:</div>
      <div class="col-md-9 col-xs-12">
        <textarea id="calcSnippetText" cols="30"></textarea>
      </div>
      <button id="calcCopyToClipboard" class="col-md-3 col-xs-12">Copy to clipboard</button>
    </div>
  </div>
</div>

<div style="margin-top: 30px" class='calcDataWrapper' style='display: none'></div>
<style>
  #calcSnippet textarea{
    color: black;
  }
</style>
</div>
