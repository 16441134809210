import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {ProvinceSelectComponent} from './components/provinceSelect/provinceSelect.component'


@NgModule({
  imports: [CommonModule],
  declarations: [ProvinceSelectComponent],
  exports: [
    ProvinceSelectComponent
  ]
})
export class ProvinceSelect {}
