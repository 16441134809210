import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { currentUserSelector } from 'src/app/auth/store/selectors';
import { CurrentUserInterface } from '../../types/currentUser.interface';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    { path: '/dashboard',         title: 'Dashboard',         icon:'nc-bank',       class: '' },
    { path: '/widgets',           title: 'Widgets',          icon:'nc-settings-gear-65',    class: '' },
    // { path: '/maps',          title: 'Maps',              icon:'nc-pin-3',      class: '' },
    // { path: '/notifications', title: 'Notifications',     icon:'nc-bell-55',    class: '' },
    { path: '/settings',         title: 'Settings',          icon:'nc-settings',    class: '' },
    { path: '/crm',              title: 'Leads',               icon:'nc-world-2',    class: '' },
    // { path: '/openLeads',        title: 'Open Leads',        icon:'nc-time-alarm',    class: '' },
    { path: '/team',             title: 'Team',               icon:'nc-atom',  class: '' },
    { path: '/profile',          title: 'User Profile',      icon:'nc-single-02',  class: '' },
    // { path: '/table',         title: 'Table List',        icon:'nc-tile-56',    class: '' },
    // { path: '/typography',    title: 'Typography',        icon:'nc-caps-small', class: '' },
    { path: '/packages',          title: 'Packages',          icon:'nc-spaceship',  class: 'active-pro' }
];

@Component({
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    currentUser$: Observable<CurrentUserInterface | null>
    constructor(private store: Store) {}
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.currentUser$ = this.store.pipe(select(currentUserSelector))
    }

    @Output() public hideSidebarEvent:EventEmitter<boolean> = new EventEmitter<boolean>();
    hideSidebar() {
      console.log('hideSidebar')
      this.hideSidebarEvent.emit();
    }
}
