import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core'

import {
  leadsFeatureSelector,
  getLeadsSelector
} from 'src/app/crm/store/selectors'
import {Store, select} from '@ngrx/store'
import {Subscription, Observer, Observable} from 'rxjs'
import {LeadsInterface} from 'src/app/shared/types/leads.interface'

import {getLeadsAction} from 'src/app/crm/store/actions/getLeads.action'

import { filter } from 'rxjs/operators'
import { Router } from '@angular/router'

declare interface TableData {
  headerRow: string[];
  dataRows: {}[];
}


@Component({
  selector: 'crm-cmp',
  templateUrl: 'crm.component.html',
  styleUrls: ['./crm.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CrmComponent implements OnInit, OnDestroy{
  public tableData: any;
  public tableDataJs: any;
  leads: LeadsInterface
  leadSubscription: Subscription
  @ViewChild('dataTable', {static: true}) table;
  @ViewChild('dataTableCard', {static: true}) tableCard;

  dtOptions: {};

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    let self = this;
    this.tableData = $(this.table.nativeElement);
    this.dtOptions = {
      columnDefs: [{
        "defaultContent": "-",
        "targets": "_all"
      }],
      // rowReorder: {
      //   selector: 'td:nth-child(2)'
      // },
      responsive: true,
      columns: [
        {title: 'ID', data: 'ID'},
        {title: 'Owner', data: 'USER_NAME'},
        {title: 'Name', data: 'NAME'},
        {title: 'Created', data: 'DATE_CREATE'},
        // {title: 'Updated', data: 'DATE_UPDATE'},
      ]
    };
    this.tableDataJs = this.tableData.DataTable(this.dtOptions);

    this.store.dispatch(getLeadsAction())
    this.leadSubscription = this.store.pipe(select(getLeadsSelector)).subscribe((leads) => {
      this.leads = leads;
      if(leads.leads)this.tableDataJs.rows.add(leads.leads).draw();
      this.tableData.off('click').on('click', 'tr', function () {
        if(window.innerWidth < 900) return;
        let leadID = self.tableDataJs.row( this ).data().ID;
        self.router.navigate(['lead/'+leadID])
      } );
      setTimeout(function (){
        self.tableCard.nativeElement.classList.remove('card-loader')
      }, 1000)
    })
  }

  ngAfterViewInit(){
    let self = this;
    setTimeout(function (){
      self.tableCard.nativeElement.classList.remove("unloaded");
      self.tableCard.nativeElement.classList.add("loading");
      setTimeout(function (){
        self.tableCard.nativeElement.classList.remove("loading");
      }, 500)
    }, 100)
  }

  ngOnDestroy(): void {
    this.leadSubscription.unsubscribe()
  }
}
