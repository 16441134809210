import { HttpClient, HttpParams } from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core'


@Component({
  selector: 'banks-select-cmp',
  templateUrl: 'banksSelect.component.html'
})

export class BanksSelectComponent implements OnInit{
  @Input('selectID') selectID = '';
  @Input('multiple') multiple = '';
  @Input('hideAll') hideAll = '';
  public Banks: any

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit() {
    const formData = new FormData();
    formData.append('api', "getBanks");
    formData.append('json', "true");


    this.httpClient.post('https://mortgages.ratepal.ca/api/backend.php', formData).subscribe(data => this.Banks = data);
  }

}
