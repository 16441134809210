import {Component, OnDestroy, OnInit, ViewChild, Input} from '@angular/core'
import { Router,ActivatedRoute } from '@angular/router';
import {
  leadFeatureSelector,
  getLeadSelector,
  updateLeadSelector
} from 'src/app/lead/store/selectors'
import {Store, select} from '@ngrx/store'
import {Subscription, Observer, Observable} from 'rxjs'
import {LeadInterface} from 'src/app/lead/types/lead.interface'
import {OpenLeadsComponent} from 'src/app/openLeads/components/openLeads/openLeads.component'

import {getLeadAction, updateLeadAction} from 'src/app/lead/store/actions/getLead.action'

import {BrowserModule } from '@angular/platform-browser';

import { filter } from 'rxjs/operators'
import { sendMessgaeService } from 'src/app/shared/modules/messageBox/services/sendMessgae.service';
import { Select2Data, Select2Module, Select2Option, Select2Value } from 'ng-select2-component';

import {DatePickerComponent} from 'ng2-date-picker';
import { DatePipe, JsonPipe } from '@angular/common';



declare interface TableData {
  headerRow: string[];
  dataRows: {}[];
}


@Component({
  selector: 'lead-cmp',
  templateUrl: 'lead.component.html',
  styleUrls: ['./lead.component.scss']
})

export class LeadComponent implements OnInit, OnDestroy{
  inputClass: string
  isNumber: boolean
  inputDisabled: string
  lead: LeadInterface
  lead_app_data: {} | any
  lead_history_data: {} | any
  leadSubscription: Subscription
  leadUpdateSubscription: Subscription
  lead$: Observable<LeadInterface | null>
  leadId;
  isBlocked = <any> ""
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  activeTab = 0;
  public clickFunction: Function;
  mortgageType: Select2Data = [
    {
      value: 'v1',
      label: 'Purchase'
    },
    {
      value: 'v2',
      label: 'Pre-approval'
    },
    {
      value: 'v3',
      label: 'Renewal'
    },
    {
      value: 'v4',
      label: 'Refinance'
    },
    {
      value: 'v5',
      label: 'Reverse Mortgage'
    },
    {
      value: 'v6',
      label: 'Construction Mortgage'
    }
  ]
  within: Select2Data=[
    {
      value: 'v1',
      label: 'I have an accepted offer'
    },
    {
      value: 'v2',
      label: 'Within 45 days'
    },
    {
      value: 'v3',
      label: 'Within 90 days'
    },
    {
      value: 'v4',
      label: 'Within 120 days'
    },
    {
      value: 'v5',
      label: 'Not sure'
    }
  ]
  yesNo: Select2Data=[
    {
      value: 'v1',
      label: 'Yes'
    },
    {
      value: 'v2',
      label: 'No'
    }
  ]
  yesNoTrueFalse: Select2Data=[
    {
      value: 'true',
      label: 'Yes'
    },
    {
      value: 'false',
      label: 'No'
    }
  ]
  mortgagePlan: Select2Data = [
    {
      value: "Fixed",
      label: "Fixed"
    },
    {
      value: "Variable",
      label: "Variable"
    }
  ]
  mortgageRatePeriod: Select2Data = [
    {
      value: "1",
      label: "1"
    },
    {
      value: "2",
      label: "2"
    },
    {
      value: "3",
      label: "3"
    },
    {
      value: "4",
      label: "4"
    },
    {
      value: "5",
      label: "5"
    },
    {
      value: "7",
      label: "7"
    },
    {
      value: "10",
      label: "10"
    }
  ]
  usedFor: Select2Data = [
    {
      value: "v1",
      label: "Primary Residence"
    },
    {
      value: "v2",
      label: "Rental Property"
    },
    {
      value: "v3",
      label: "Vacation Home"
    },
    {
      value: "v4",
      label: "Commercial"
    },
    {
      value: "v5",
      label: "For Re-sale"
    },
    {
      value: "v6",
      label: "Other"
    }
  ]
  propertyIs: Select2Data = [
    {
      value: "v1",
      label: "Appartment/Townhouse"
    },
    {
      value: "v2",
      label: "Single Family Home"
    },
    {
      value: "v3",
      label: "Duplex or Multi-Unit"
    },
    {
      value: "v4",
      label: "Land"
    },
    {
      value: "v5",
      label: "Other"
    }
  ]

  constructor(private store: Store, private _Activatedroute:ActivatedRoute, private sms: sendMessgaeService, private datePipe: DatePipe) {

  }

  ngOnInit(): void {
    this.lead$ = this.store.pipe(select(getLeadSelector))
    this.leadId = this._Activatedroute.snapshot.params.leadId;
    this.inputClass = 'form-control'
    this.inputDisabled = 'disabled'
    this.store.dispatch(getLeadAction())
    this.leadSubscription = this.store.pipe(select(getLeadSelector)).subscribe((lead) => {
      this.lead = lead;
      if (lead.history) {
        this.lead_history_data = JSON.parse(lead.history).leads_history
        if(this.lead_history_data) {
          // this gives an object with dates as keys
          const groups = {};
          this.lead_history_data.map((event) => {
            const date = this.transformDate(event.DATE_CREATE);
            if (!groups[date]) {
              groups[date] = [];
            }
            event.EVENT = event.EVENT.toLowerCase();
            event.EVENT = event.EVENT[0].toUpperCase() + event.EVENT.slice(1);
            groups[date].push(event);
          });

          const arrayReverseObj =
            obj => Object.keys(obj).sort().reverse().map(key => ({key: key, events: obj[key]}));

          const arrayReverseObjEvents =
            obj => Object.keys(obj).sort().reverse().map(key => (obj[key]) );

          this.lead_history_data = arrayReverseObj(groups);

          this.lead_history_data = this.lead_history_data.map((data) => {
            data.events = arrayReverseObjEvents(data.events)
          })

        }
      }

      if (lead.app_data) this.lead_app_data = JSON.parse(lead.app_data)

      // this.isBlocked = lead.affiliation_id == 0 ? true : false
    })

    this.leadUpdateSubscription = this.store.pipe(select(updateLeadSelector)).subscribe((lead) => {
      this.lead = lead;

      if (lead.history) {
        this.lead_history_data = JSON.parse(lead.history).leads_history
        if(this.lead_history_data) {

          // this gives an object with dates as keys
          const groups = {};
          this.lead_history_data.map((event) => {
            const date = this.transformDate(event.DATE_CREATE);
            if (!groups[date]) {
              groups[date] = [];
            }
            event.EVENT = event.EVENT.toLowerCase();
            event.EVENT = event.EVENT[0].toUpperCase() + event.EVENT.slice(1);
            groups[date].push(event);
          });

          const arrayReverseObj =
            obj => Object.keys(obj).sort().reverse().map(key => ({key: key, events: obj[key]}));

          const arrayReverseObjEvents =
            obj => Object.keys(obj).sort().reverse().map(key => (obj[key]) );

          this.lead_history_data = arrayReverseObj(groups);

          this.lead_history_data.map((data) => {
            data.events = arrayReverseObjEvents(data.events)
          })
        }
      }

      if (lead.app_data) this.lead_app_data = JSON.parse(lead.app_data)

      // this.isBlocked = lead.affiliation_id == 0 ? true : false

      setTimeout(function (){
        var mainInfoCardElement = document.getElementById("mainInfoCard");
        var propertyInfoCardElement = document.getElementById("propertyInfoCard");
        var mortgageInfoCardElement = document.getElementById("mortgageInfoCard");

        mainInfoCardElement.classList.remove('card-loader')
        propertyInfoCardElement.classList.remove('card-loader')
        mortgageInfoCardElement.classList.remove('card-loader')
      }, 1000)
    })
  }

  ngOnDestroy(): void {
    this.leadSubscription.unsubscribe()
    // this.leadUpdateSubscription.unsubscribe()
  }

  ngAfterViewInit(): void{
    var mainInfoCardElement = document.getElementById("mainInfoCard");
    var propertyInfoCardElement = document.getElementById("propertyInfoCard");
    var mortgageInfoCardElement = document.getElementById("mortgageInfoCard");
    setTimeout(function (){
      mainInfoCardElement.classList.remove("unloaded");
    }, 100)
    setTimeout(function (){
      propertyInfoCardElement.classList.remove("unloaded");
    }, 300)
    setTimeout(function (){
      mortgageInfoCardElement.classList.remove("unloaded");
    }, 500)

    // @ts-ignore
    setHeaderClick()
  }

  transformDate(dt) {
    var date = new Date(dt);
    return this.datePipe.transform(date, 'MMM d,y'); //whatever format you need.
  }

  transformTime(dt) {
    var date = new Date(dt);
    return this.datePipe.transform(date, 'h:mm a'); //whatever format you need.
  }

  myCallbackFunction = (val: string, field: string): void => {
    this.store.dispatch(updateLeadAction({payload: {id: this.leadId,  field: field, value:val}}))
  }

  changeTab(id): void{
    this.activeTab = id;
  }

  setUserLead(): void {

  }
}
function Import() {
    throw new Error('Function not implemented.');
}

