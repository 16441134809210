
<div class="sidebar-wrapper" >
  <div class="lg">
    <a href='/#/profile' class="simple-text logo-mini">
      <div class="logo-image-small">
           <img class="user-pic" [src]="(currentUser$ | async).image" />
            {{ (currentUser$ | async).firstname}} {{ (currentUser$ | async).lastname}}
        </div>
      </a>
     <div class="rating">Rating: {{ (currentUser$ | async).rating }} </div>
<!--      <a href='/' class="simple-text logo-normal">-->
<!--        Brokers Portal-->
<!--      </a>-->
    </div>
      <ul class="nav">
          <li *ngFor="let menuItem of menuItems" routerLinkActive="active" class="{{menuItem.class}}">
              <a [routerLink]="[menuItem.path]" (click)="hideSidebar()">
                  <i class="nc-icon {{menuItem.icon}}"></i>
                  <p>{{menuItem.title}}</p>
              </a>
          </li>
      </ul>
  </div>
