import {LeadInterface} from '../types/lead.interface'
import {createReducer, on, Action} from '@ngrx/store'
import {
  getLeadAction,
  getLeadSuccessAction,
  getLeadFailureAction,
  updateLeadSuccessAction,
  updateLeadAction,
  updateLeadFailureAction
} from 'src/app/lead/store/actions/getLead.action'

const initialState: LeadInterface = {

}

const leadReducers = createReducer(
  initialState,
  on(
    getLeadAction,
    (state, action): LeadInterface => ({
      ...state,
    })
  ),
  on(
    getLeadSuccessAction,
    (state, action): LeadInterface => ({
      ...state,
      id: action.id,
      owner_id: action.owner_id,
      owner_title: action.owner_title,
      status_id: action.status_id,
      affiliation_id: action.affiliation_id,
      client_name: action.client_name,
      client_phone: action.client_phone,
      client_email: action.client_email,
      property_location: action.property_location,
      property_value: action.property_value,
      mortgage_type_id: action.mortgage_type_id,
      mortgage_downpayment: action.mortgage_downpayment,
      mortgage_price: action.mortgage_price,
      mortgage_provider: action.mortgage_provider,
      mortgage_term: action.mortgage_term,
      mortgage_rate: action.mortgage_rate,
      referral: action.referral,
      comment: action.comment,
      created_date: action.created_date,
      app_data: action.app_data ? JSON.parse(action.app_data).postData  : null,
      source: action.source,
      updated_date: action.updated_date,
      question: action.question,
      history: action.history
    })
  ),
  on(
    getLeadFailureAction,
    (state, action): LeadInterface => ({
      ...state,
    })
  ),
  on(
    updateLeadAction,
    (state, action): LeadInterface => ({
      ...state,
    })
  ),
  on(
    updateLeadSuccessAction,
    (state, action): LeadInterface => ({
      ...state,
      id: action.id,
      owner_id: action.owner_id,
      owner_title: action.owner_title,
      status_id: action.status_id,
      affiliation_id: action.affiliation_id,
      client_name: action.client_name,
      client_phone: action.client_phone,
      client_email: action.client_email,
      property_location: action.property_location,
      property_value: action.property_value,
      mortgage_type_id: action.mortgage_type_id,
      mortgage_downpayment: action.mortgage_downpayment,
      mortgage_price: action.mortgage_price,
      mortgage_provider: action.mortgage_provider,
      mortgage_term: action.mortgage_term,
      mortgage_rate: action.mortgage_rate,
      referral: action.referral,
      comment: action.comment,
      created_date: action.created_date,
      app_data: action.app_data ? JSON.parse(action.app_data).postData  : null,
      updated_date: action.updated_date,
      question: action.question,
      history: action.history
    })
  ),
  on(
    updateLeadFailureAction,
    (state, action): LeadInterface => ({
      ...state,
    })
  ),
)

export function reducers(state: LeadInterface, action: Action) {
  return leadReducers(state, action)
}
