<form action="">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <h6 (click)="changeTab(0)" class="tab {{activeTab == 0 ? 'active' : ''}}">Lead info</h6>
          <h6 (click)="changeTab(1)" class="tab {{activeTab == 1 ? 'active' : ''}}" *ngIf="lead.app_data">Application info</h6>
          <h6 (click)="changeTab(2)" class="tab {{activeTab == 2 ? 'active' : ''}}">Lead timeline</h6>
        </div>
      </div>
      <span class="tabData {{activeTab == 0 ? 'active' : ''}}">
        <div id="mainInfoCard" class="card card-loader unloaded">
          <div class="card-header">
            <h5>Main info</h5>
          </div>
          <div class="card-body">
            <div class="row">
          <div class="col-47">
            <div class="form-group d-flex">
              <div class="field-title">Lead Owner</div>
  <!--            <div *ngIf="isBlocked; then isBlockedBlock else notBlockedBlock"></div>-->
              <div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.owner_title" field="leadOwner" [callbackFunction]="myCallbackFunction" inputDisabled="disabled"></editable-input-cmp></div>
  <!--            <ng-template #notBlockedBlock><div class="field-input"><editable-input-cmp field="leadOwner" [inputClass]="inputClass" [callbackFunction]="myCallbackFunction" [inputValue]="lead.owner_title"></editable-input-cmp></div></ng-template>-->
            </div>
            <div class="form-group d-flex">
              <div class="field-title">Lead Source</div>
              <!--            <div *ngIf="isBlocked; then isBlockedBlock else notBlockedBlock"></div>-->
              <div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.source" field="leadOwner" [callbackFunction]="myCallbackFunction" inputDisabled="disabled"></editable-input-cmp></div>
              <!--            <ng-template #notBlockedBlock><div class="field-input"><editable-input-cmp field="leadOwner" [inputClass]="inputClass" [callbackFunction]="myCallbackFunction" [inputValue]="lead.owner_title"></editable-input-cmp></div></ng-template>-->
            </div>
            <div class="form-group d-flex">
              <div class="field-title">Lead Status</div>
              <div *ngIf="isBlocked; then isBlockedBlock0 else notBlockedBlock0"></div>
              <ng-template #isBlockedBlock0><div class="field-input"><lead-status-select-cmp></lead-status-select-cmp></div></ng-template>
              <ng-template #notBlockedBlock0><div class="field-input"><lead-status-select-cmp></lead-status-select-cmp></div></ng-template>
            </div>
          </div>
          <div class="col-47">
            <div class="form-group d-flex">
              <div class="field-title">Client Name</div>
              <div *ngIf="isBlocked; then isBlockedBlock1 else notBlockedBlock1"></div>
              <ng-template #isBlockedBlock1><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.client_name" field="NAME" [callbackFunction]="myCallbackFunction" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
              <ng-template #notBlockedBlock1><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.client_name" field="NAME" [callbackFunction]="myCallbackFunction"></editable-input-cmp></div></ng-template>
            </div>
            <div class="form-group d-flex">
              <div class="field-title">Client Phone</div>
              <div *ngIf="isBlocked; then isBlockedBlock2 else notBlockedBlock2"></div>
              <ng-template #isBlockedBlock2><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.client_phone" field="PHONE" [callbackFunction]="myCallbackFunction" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
              <ng-template #notBlockedBlock2><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.client_phone" field="PHONE" [callbackFunction]="myCallbackFunction" ></editable-input-cmp></div></ng-template>
            </div>
            <div class="form-group d-flex">
              <div class="field-title">Client Email</div>
              <div *ngIf="isBlocked; then isBlockedBlock3 else notBlockedBlock3"></div>
              <ng-template #isBlockedBlock3><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.client_email" field="EMAIL" [callbackFunction]="myCallbackFunction" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
              <ng-template #notBlockedBlock3><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.client_email" field="EMAIL" [callbackFunction]="myCallbackFunction" ></editable-input-cmp></div></ng-template>
            </div>
          </div>
        </div>
          </div>
        </div>
        <div id="propertyInfoCard" class="card card-loader unloaded">
          <div class="card-header">
            <h5>Property info</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-47">
                <div class="form-group d-flex">
                  <div class="field-title">Location</div>
                  <div *ngIf="isBlocked; then isBlockedBlock4 else notBlockedBlock4"></div>
                  <ng-template #isBlockedBlock4><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.property_location" field="LOCATION" [callbackFunction]="myCallbackFunction" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                  <ng-template #notBlockedBlock4><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.property_location" field="LOCATION" [callbackFunction]="myCallbackFunction" ></editable-input-cmp></div></ng-template>
                </div>
              </div>
              <div class="col-47">
                <div class="form-group d-flex">
                  <div class="field-title">Value</div>
                  <div *ngIf="isBlocked; then isBlockedBlock5 else notBlockedBlock5"></div>
                  <ng-template #isBlockedBlock5><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.property_value" field="PROPERTY_VALUE" [callbackFunction]="myCallbackFunction" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                  <ng-template #notBlockedBlock5><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.property_value" field="PROPERTY_VALUE" [callbackFunction]="myCallbackFunction" ></editable-input-cmp></div></ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!lead.app_data">
          <div id="mortgageInfoCard" class="card card-loader unloaded">
            <div class="card-header">
              <h5>Mortgage info</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-47">
                  <div class="form-group d-flex">
                    <div class="field-title">Type</div>
                    <div *ngIf="isBlocked; then isBlockedBlock6 else notBlockedBlock6"></div>
                    <ng-template #isBlockedBlock6><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.mortgage_type_id" field="MORTGAGE_TYPE" [callbackFunction]="myCallbackFunction" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock6><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.mortgage_type_id" field="MORTGAGE_TYPE" [callbackFunction]="myCallbackFunction"></editable-input-cmp></div></ng-template>
                  </div>
                  <div class="form-group d-flex">
                    <div class="field-title">Down payment</div>
                    <div *ngIf="isBlocked; then isBlockedBlock7 else notBlockedBlock7"></div>
                    <ng-template #isBlockedBlock7><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.mortgage_downpayment" field="DOWN_PAYMENT" [callbackFunction]="myCallbackFunction" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock7><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.mortgage_downpayment" field="DOWN_PAYMENT" [callbackFunction]="myCallbackFunction"></editable-input-cmp></div></ng-template>
                  </div>
                  <div class="form-group d-flex">
                    <div class="field-title">Price</div>
                    <div *ngIf="isBlocked; then isBlockedBlock8 else notBlockedBlock8"></div>
                    <ng-template #isBlockedBlock8><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.mortgage_price" field="AMOUNT" [callbackFunction]="myCallbackFunction" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock8><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.mortgage_price" field="AMOUNT" [callbackFunction]="myCallbackFunction"></editable-input-cmp></div></ng-template>
                  </div>
                </div>
                <div class="col-47">
                  <div class="form-group d-flex">
                    <div class="field-title">Provider</div>
                    <div *ngIf="isBlocked; then isBlockedBlock9 else notBlockedBlock9"></div>
                    <ng-template #isBlockedBlock9><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.mortgage_provider" field="PROVIDER" [callbackFunction]="myCallbackFunction" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock9><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.mortgage_provider" field="PROVIDER" [callbackFunction]="myCallbackFunction"></editable-input-cmp></div></ng-template>
                  </div>
                  <div class="form-group d-flex">
                    <div class="field-title">Term</div>
                    <div *ngIf="isBlocked; then isBlockedBlock10 else notBlockedBlock10"></div>
                    <ng-template #isBlockedBlock10><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.mortgage_term" field="SROK" [callbackFunction]="myCallbackFunction" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock10><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.mortgage_term" field="SROK" [callbackFunction]="myCallbackFunction"></editable-input-cmp></div></ng-template>
                  </div>
                  <div class="form-group d-flex">
                    <div class="field-title">Rate</div>
                    <div *ngIf="isBlocked; then isBlockedBlock11 else notBlockedBlock11"></div>
                    <ng-template #isBlockedBlock11><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.mortgage_rate" field="RATE" [callbackFunction]="myCallbackFunction" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock11><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.mortgage_rate" field="RATE" [callbackFunction]="myCallbackFunction"></editable-input-cmp></div></ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
      <span class="tabData {{activeTab == 1 ? 'active' : ''}}">
        <div *ngIf="lead.app_data">
          <div class="card">
          <div class="card-header">
            <h5>Mortgage</h5>
          </div>
          <div class="card-body">
<!--            {{lead_app_data.mortgage | json}}-->
            <div class="row">
              <div class="col-47">
                <div class="form-group">
                  <div class="field-title">Mortgage Type</div>
                  <div class="field-select">
                    <select2
                      [data]="mortgageType"
                      [value]="lead_app_data.mortgage.typeID">
                    </select2>
                  </div>
                </div>
                <div class="form-group">
                  <div class="field-title wide">When Are You Looking to Purchase a Property?</div>
                    <div class="field-select">
                       <select2
                         [data]="within"
                         [value]="lead_app_data.mortgage.typeID">
                      </select2>
                    </div>
                </div>
                <div class="form-group">
                  <div class="field-title wide">Are You a First Time Buyer?</div>
                  <div class="field-select">
                     <select2
                       [data]="yesNo"
                       [value]="lead_app_data.mortgage.firstTimeID">
                    </select2>
                  </div>
                </div>
                <div class="form-group">
                  <div class="field-title wide">{{lead_app_data.mortgage.priceQuestion}}</div>
                  <div *ngIf="!isBlocked; then isBlockedBlock_priceQuestion else notBlockedBlock_priceQuestion"></div>
                  <ng-template #isBlockedBlock_priceQuestion><div class="field-input wide"><editable-input-cmp [isNumber]="true" [inputClass]="inputClass" [inputValue]="formatter.format(lead_app_data.mortgage.price)" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                  <ng-template #notBlockedBlock_priceQuestion><div class="field-input wide"><editable-input-cmp [isNumber]="true" [inputClass]="inputClass" [inputValue]="formatter.format(lead_app_data.mortgage.price)"></editable-input-cmp></div></ng-template>
                </div>
                <div *ngIf="lead_app_data.mortgage.typeID && lead_app_data.mortgage.typeID == 'v1' || lead_app_data.mortgage.typeID && lead_app_data.mortgage.typeID == 'v2'">
                  <div *ngIf="lead_app_data.mortgage.downPayment" class="form-group">
                    <div class="field-title wide">{{lead_app_data.mortgage.downPaymentQuestion}}</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_downPaymentQuestion else notBlockedBlock_downPaymentQuestion"></div>
                    <ng-template #isBlockedBlock_downPaymentQuestion><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="formatter.format(lead_app_data.mortgage.downPayment)" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_downPaymentQuestion><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="formatter.format(lead_app_data.mortgage.downPayment)"></editable-input-cmp></div></ng-template>
                  </div>
                  <div *ngIf="lead_app_data.mortgage.downPayment" class="form-group">
                    <div class="field-title wide">Do you already have a mortgage approval offer?</div>
                    <div class="field-select">
                       <select2
                         [data]="yesNo"
                         [value]="lead_app_data.mortgage.alreadyApproval2ID">
                      </select2>
                    </div>
                  </div>
                  <div *ngIf="lead_app_data.mortgage.alreadyApproval2 && lead_app_data.mortgage.alreadyApproval2 == 'Yes'">
                    <div *ngIf="lead_app_data.mortgage.downPayment" class="form-group">
                      <div class="field-title wide">Approval rate:</div>
                      <div *ngIf="!isBlocked; then isBlockedBlock_approvalRate2 else notBlockedBlock_approvalRate2"></div>
                      <ng-template #isBlockedBlock_approvalRate2><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.approvalRate2" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                      <ng-template #notBlockedBlock_approvalRate2><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.approvalRate2"></editable-input-cmp></div></ng-template>
                    </div>
                    <div *ngIf="lead_app_data.mortgage.approvalLender2" class="form-group">
                      <div class="field-title wide">Approval lender:</div>
                      <div *ngIf="!isBlocked; then isBlockedBlock_approvalLender2 else notBlockedBlock_approvalLender2"></div>
                      <ng-template #isBlockedBlock_approvalLender2><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.approvalLender2" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                      <ng-template #notBlockedBlock_approvalLender2><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.approvalLender2"></editable-input-cmp></div></ng-template>
                    </div>
                    <div *ngIf="lead_app_data.mortgage.approvalLender2" class="form-group">
                      <div class="field-title wide">Approval rate type:</div>
                        <div class="field-select">
                         <select2
                           [data]="mortgagePlan"
                           [value]="lead_app_data.mortgage.approvalRateType2">
                        </select2>
                      </div>
                    </div>
                    <div *ngIf="lead_app_data.mortgage.approvalLender2" class="form-group">
                      <div class="field-title wide">Approval period:</div>
                        <div class="field-select">
                         <select2
                           [data]="mortgageRatePeriod"
                           [value]="lead_app_data.mortgage.approvalRatePeriod2">
                        </select2>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="lead_app_data.mortgage.typeID && lead_app_data.mortgage.typeID == 'v3' || lead_app_data.mortgage.typeID && lead_app_data.mortgage.typeID == 'v4'">
                  <div class="form-group">
                    <div class="field-title wide">Are your looking to borrow more than your remaining principial and refinance?</div>
                    <div class="field-select">
                       <select2
                         [data]="yesNoTrueFalse"
                         [value]="lead_app_data.mortgage.lookingToBorrow">
                      </select2>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="field-title wide">Additional funds you are looking to borrow</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_borrowMorePrincipial else notBlockedBlock_borrowMorePrincipial"></div>
                    <ng-template #isBlockedBlock_borrowMorePrincipial><div class="field-input wide"><editable-input-cmp [isNumber]="true" [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.borrowMorePrincipial" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_borrowMorePrincipial><div class="field-input wide"><editable-input-cmp [isNumber]="true" [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.borrowMorePrincipial"></editable-input-cmp></div></ng-template>
                  </div>
                  <div *ngIf="lead_app_data.mortgage.rentalSuite == '1' && lead_app_data.mortgage.rental" class="form-group">
                    <div class="field-title wide">Monthly Rental Income</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_rent else notBlockedBlock_rent"></div>
                    <ng-template #isBlockedBlock_rent><div class="field-input wide"><editable-input-cmp [isNumber]="true" [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.rent" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_rent><div class="field-input wide"><editable-input-cmp [isNumber]="true" [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.rent"></editable-input-cmp></div></ng-template>
                  </div>
                  <p>What is your current mortgage details?</p>
                  <div *ngIf="lead_app_data.mortgage.currentRate" class="form-group">
                    <div class="field-title wide">Current rate:</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_currentRate else notBlockedBlock_currentRate"></div>
                    <ng-template #isBlockedBlock_currentRate><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.currentRate" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_currentRate><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.currentRate"></editable-input-cmp></div></ng-template>
                  </div>
                  <div *ngIf="lead_app_data.mortgage.currentLender" class="form-group">
                    <div class="field-title wide">Current lender:</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_currentLender else notBlockedBlock_currentLender"></div>
                    <ng-template #isBlockedBlock_currentLender><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.currentLender" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_currentLender><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.currentLender"></editable-input-cmp></div></ng-template>
                  </div>
                  <div *ngIf="lead_app_data.mortgage.currentRateType" class="form-group">
                    <div class="field-title wide">Current rate type:</div>
                    <div class="field-select">
                         <select2
                           [data]="mortgagePlan"
                           [value]="lead_app_data.mortgage.currentRateType">
                        </select2>
                      </div>
                  </div>
                  <div *ngIf="lead_app_data.mortgage.currentRateType" class="form-group">
                    <div class="field-title wide">Current period:</div>
                    <div class="field-select">
                         <select2
                           [data]="mortgageRatePeriod"
                           [value]="lead_app_data.mortgage.currentRatePeriod">
                        </select2>
                      </div>
                  </div>
                  <div *ngIf="lead_app_data.mortgage.renewalDate && lead_app_data.mortgage.renewalMonth && lead_app_data.mortgage.renewalYear">
                    <p>When is your mortgage renewal date?<br><b>{{lead_app_data.mortgage.renewalDate}}</b></p>
<!--                    <dp-date-picker></dp-date-picker>-->
                  </div>
                </div>
              </div>
              <div class="col-47">
                <div *ngIf="lead_app_data.mortgage.typeID && lead_app_data.mortgage.typeID == 'v4'">
                  <div *ngIf="lead_app_data.mortgage.currentLender" class="form-group">
                    <div class="field-title wide">What is Outstanding Balance of Your Current Mortgage(s)?</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_outstandingBalanceSlider else notBlockedBlock_outstandingBalanceSlider"></div>
                    <ng-template #isBlockedBlock_outstandingBalanceSlider><div class="field-input wide"><editable-input-cmp [isNumber]="true" [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.outstandingBalanceSlider" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_outstandingBalanceSlider><div class="field-input wide"><editable-input-cmp [isNumber]="true" [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.outstandingBalanceSlider"></editable-input-cmp></div></ng-template>
                  </div>
                  <div *ngIf="lead_app_data.mortgage.currentLender" class="form-group">
                    <div class="field-title wide">Additional funds you are looking to borrow</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_borrowSummSlider else notBlockedBlock_borrowSummSlider"></div>
                    <ng-template #isBlockedBlock_borrowSummSlider><div class="field-input wide"><editable-input-cmp [isNumber]="true" [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.borrowSummSlider" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_borrowSummSlider><div class="field-input wide"><editable-input-cmp [isNumber]="true" [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.borrowSummSlider"></editable-input-cmp></div></ng-template>
                  </div>
                </div>
                <div *ngIf="lead_app_data.mortgage.typeID && lead_app_data.mortgage.typeID == 'v5'">
                  <div *ngIf="lead_app_data.mortgage.supplement" class="form-group">
                    <div class="field-title wide">Additional funds you are looking to recieve?</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_supplement else notBlockedBlock_supplement"></div>
                    <ng-template #isBlockedBlock_supplement><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.supplement" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_supplement><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.supplement"></editable-input-cmp></div></ng-template>
                  </div>
  <!--                <div *ngIf="lead_app_data.mortgage.supplement && lead_app_data.mortgage.borrowMorePrincipialSliderReverse">-->
  <!--                  <p>Additional funds you are looking to recieve?<br><b>{{lead_app_data.mortgage.supplement}} - {{lead_app_data.mortgage.borrowMorePrincipialSliderReverse}}</b></p>-->
  <!--                </div>-->
                  <div *ngIf="lead_app_data.mortgage.supplement" class="form-group">
                    <div class="field-title wide">Do you already have a mortgage approval offer?</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_alreadyApproval2 else notBlockedBlock_alreadyApproval2"></div>
                    <ng-template #isBlockedBlock_alreadyApproval2><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.alreadyApproval2" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_alreadyApproval2><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.alreadyApproval2"></editable-input-cmp></div></ng-template>
                  </div>
                  <div *ngIf="lead_app_data.mortgage.alreadyApproval2 && lead_app_data.mortgage.alreadyApproval2 == 'Yes'">
                    <div *ngIf="lead_app_data.mortgage.approvalRate2" class="form-group">
                      <div class="field-title wide">Approval rate:</div>
                      <div *ngIf="!isBlocked; then isBlockedBlock_approvalRate2 else notBlockedBlock_approvalRate2"></div>
                      <ng-template #isBlockedBlock_approvalRate2><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.approvalRate2" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                      <ng-template #notBlockedBlock_approvalRate2><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.approvalRate2"></editable-input-cmp></div></ng-template>
                    </div>
                    <div *ngIf="lead_app_data.mortgage.approvalLender2" class="form-group">
                      <div class="field-title wide">Approval lender:</div>
                      <div *ngIf="!isBlocked; then isBlockedBlock_approvalLender2 else notBlockedBlock_approvalLender2"></div>
                      <ng-template #isBlockedBlock_approvalLender2><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.approvalLender2" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                      <ng-template #notBlockedBlock_approvalLender2><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.approvalLender2"></editable-input-cmp></div></ng-template>
                    </div>
                    <div *ngIf="lead_app_data.mortgage.approvalRateType2" class="form-group">
                      <div class="field-title wide">Approval rate type:</div>
                      <div class="field-select">
                           <select2
                             [data]="mortgagePlan"
                             [value]="lead_app_data.mortgage.approvalRateType2">
                          </select2>
                        </div>
                    </div>
                    <div *ngIf="lead_app_data.mortgage.approvalRatePeriod2" class="form-group">
                      <div class="field-title wide">Approval period:</div>
                      <div class="field-select">
                           <select2
                             [data]="mortgageRatePeriod"
                             [value]="lead_app_data.mortgage.approvalRatePeriod2">
                          </select2>
                        </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="lead_app_data.mortgage.typeID && lead_app_data.mortgage.typeID == 'v6'">
                  <div *ngIf="lead_app_data.mortgage.alreadyPurchasedParcelOfLand" class="form-group">
                    <div class="field-title wide">Have you already purchased a parcel of land?</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_alreadyPurchasedParcelOfLand else notBlockedBlock_alreadyPurchasedParcelOfLand"></div>
                    <ng-template #isBlockedBlock_alreadyPurchasedParcelOfLand><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.alreadyPurchasedParcelOfLand" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_alreadyPurchasedParcelOfLand><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.alreadyPurchasedParcelOfLand"></editable-input-cmp></div></ng-template>
                  </div>
                  <div *ngIf="lead_app_data.mortgage.alreadyPurchasedParcelOfLand && lead_app_data.mortgage.alreadyPurchasedParcelOfLand == 'Yes' && lead_app_data.mortgage.buildExictingMortgage">
                    <div *ngIf="lead_app_data.mortgage.alreadyPurchasedParcelOfLand" class="form-group">
                      <div class="field-title wide">Is there an existing mortgage on the parcel of land where you are planning to build?</div>
                      <div *ngIf="!isBlocked; then isBlockedBlock_alreadyPurchasedParcelOfLand else notBlockedBlock_alreadyPurchasedParcelOfLand"></div>
                      <ng-template #isBlockedBlock_alreadyPurchasedParcelOfLand><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.alreadyPurchasedParcelOfLand" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                      <ng-template #notBlockedBlock_alreadyPurchasedParcelOfLand><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.alreadyPurchasedParcelOfLand"></editable-input-cmp></div></ng-template>
                    </div>
                  </div>
                  <div *ngIf="lead_app_data.mortgage.alreadyPurchasedParcelOfLand && lead_app_data.mortgage.alreadyPurchasedParcelOfLand == 'Yes' && lead_app_data.mortgage.buildExictingMortgage && lead_app_data.mortgage.outstandingBalanceConstruction">
                    <div *ngIf="lead_app_data.mortgage.alreadyPurchasedParcelOfLand" class="form-group">
                      <div class="field-title wide">What is Outstanding Balance of Your Current Mortgage(s)?</div>
                      <div *ngIf="!isBlocked; then isBlockedBlock_outstandingBalanceConstruction else notBlockedBlock_outstandingBalanceConstruction"></div>
                      <ng-template #isBlockedBlock_outstandingBalanceConstruction><div class="field-input wide"><editable-input-cmp [isNumber]="true"  [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.outstandingBalanceConstruction" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                      <ng-template #notBlockedBlock_outstandingBalanceConstruction><div class="field-input wide"><editable-input-cmp [isNumber]="true"  [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.outstandingBalanceConstruction"></editable-input-cmp></div></ng-template>
                    </div>
                  </div>
                  <div *ngIf="lead_app_data.mortgage.existingStrucuture" class="form-group">
                    <div class="field-title wide">Is there an existing structure (i.e. house) on that parcel of land?</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_existingStrucuture else notBlockedBlock_existingStrucuture"></div>
                    <ng-template #isBlockedBlock_existingStrucuture><div class="field-input wide"><editable-input-cmp  [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.existingStrucuture" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_existingStrucuture><div class="field-input wide"><editable-input-cmp  [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.existingStrucuture"></editable-input-cmp></div></ng-template>
                  </div>
                </div>
  <!--              <div *ngIf="lead_app_data.mortgage.usedrFor && lead_app_data.mortgage.usedrFor == 'Other'">-->
  <!--                <div *ngIf="lead_app_data.mortgage.otherProperty" class="form-group">-->
  <!--                  <div class="field-title wide">What will the Property Used For?</div>-->
  <!--                  <div *ngIf="!isBlocked; then isBlockedBlock_otherProperty else notBlockedBlock_otherProperty"></div>-->
  <!--                  <ng-template #isBlockedBlock_otherProperty><div class="field-input wide"><editable-input-cmp [isNumber]="true"  [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.otherProperty" inputDisabled="disabled"></editable-input-cmp></div></ng-template>-->
  <!--                  <ng-template #notBlockedBlock_otherProperty><div class="field-input wide"><editable-input-cmp [isNumber]="true"  [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.otherProperty"></editable-input-cmp></div></ng-template>-->
  <!--                </div>-->
  <!--              </div>-->
                <div *ngIf="lead_app_data.mortgage.usedrFor">
                  <div *ngIf="lead_app_data.mortgage.usedrFor" class="form-group">
                    <div class="field-title wide">What will the Property Used For?</div>
                    <div class="field-select">
                       <select2
                         [data]="usedFor"
                         [value]="lead_app_data.mortgage.usedrForID">
                      </select2>
                    </div>
                  </div>
                </div>
                <div *ngIf="lead_app_data.mortgage.propAddressMap">
                  <div *ngIf="lead_app_data.mortgage.propAddressMap" class="form-group">
                    <div class="field-title wide">Where is the Property Located?</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_propAddressMap else notBlockedBlock_propAddressMap"></div>
                    <ng-template #isBlockedBlock_propAddressMap><div class="field-input wide"><editable-textarea-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.propAddressMap" inputDisabled="disabled"></editable-textarea-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_propAddressMap><div class="field-input wide"><editable-textarea-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.propAddressMap"></editable-textarea-cmp></div></ng-template>
                  </div>
                </div>
                <div *ngIf="lead_app_data.mortgage.propertyType">
                  <div *ngIf="lead_app_data.mortgage.propertyType" class="form-group">
                    <div class="field-title wide">What type of property is it?</div>
                    <div class="field-select">
                       <select2
                         [data]="propertyIs"
                         [value]="lead_app_data.mortgage.propertyTypeID">
                      </select2>
                    </div>
                  </div>
                </div>
<!--                <div *ngIf="lead_app_data.mortgage.propertyType && lead_app_data.mortgage.propertyType != 'Other'">-->
<!--                  <div *ngIf="lead_app_data.mortgage.propertyType" class="form-group">-->
<!--                    <div class="field-title wide">What type of property is it?</div>-->
<!--                    <div *ngIf="!isBlocked; then isBlockedBlock_propertyType else notBlockedBlock_propertyType"></div>-->
<!--                    <ng-template #isBlockedBlock_propertyType><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.propertyType" inputDisabled="disabled"></editable-input-cmp></div></ng-template>-->
<!--                    <ng-template #notBlockedBlock_propertyType><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.propertyType"></editable-input-cmp></div></ng-template>-->
<!--                  </div>-->
<!--                </div>-->
                <div *ngIf="lead_app_data.mortgage.square">
                  <div *ngIf="lead_app_data.mortgage.square" class="form-group">
                    <div class="field-title wide">Square footage of the property?</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_square else notBlockedBlock_square"></div>
                    <ng-template #isBlockedBlock_square><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.square" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_square><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.square"></editable-input-cmp></div></ng-template>
                  </div>
                </div>
                <div *ngIf="lead_app_data.mortgage.strata">
                  <div *ngIf="lead_app_data.mortgage.strata" class="form-group">
                    <div class="field-title wide">Monthly strata fee?</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_strata else notBlockedBlock_strata"></div>
                    <ng-template #isBlockedBlock_strata><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.strata" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_strata><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.strata"></editable-input-cmp></div></ng-template>
                  </div>
                </div>
                <div *ngIf="lead_app_data.mortgage.tax">
                  <div *ngIf="lead_app_data.mortgage.tax" class="form-group">
                    <div class="field-title wide">Annual property taxes?</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_tax else notBlockedBlock_tax"></div>
                    <ng-template #isBlockedBlock_tax><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.tax" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_tax><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.tax"></editable-input-cmp></div></ng-template>
                  </div>
                </div>
                <div *ngIf="lead_app_data.mortgage.propertyType">
                  <div *ngIf="lead_app_data.mortgage.propertyType" class="form-group">
                    <div class="field-title wide">What type of property is it?</div>
                    <div *ngIf="!isBlocked; then isBlockedBlock_propertyType else notBlockedBlock_propertyType"></div>
                    <ng-template #isBlockedBlock_propertyType><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.propertyType" inputDisabled="disabled"></editable-input-cmp></div></ng-template>
                    <ng-template #notBlockedBlock_propertyType><div class="field-input wide"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead_app_data.mortgage.propertyType"></editable-input-cmp></div></ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>

<!--        <div *ngFor="let applicant of lead_app_data.applicants; let i = index" class="col-12">-->
<!--              <h6>Applicant {{i+1}}</h6>-->
<!--              <div class="row">-->
<!--                <div class="col-lg-4">-->
<!--                  <h6>Applicant info</h6>-->
<!--                  <div *ngIf="applicant.firstName"><p>First name: <br><b>{{applicant.firstName}}</b></p></div>-->
<!--                  <div *ngIf="applicant.middleName"><p>Middle name: <br><b>{{applicant.middleName}}</b></p></div>-->
<!--                  <div *ngIf="applicant.lastName"><p>Last name: <br><b>{{applicant.lastName}}</b></p></div>-->
<!--                  <div *ngIf="applicant.phone"><p>Phone: <br><b>{{applicant.phone}}</b></p></div>-->
<!--                  <div *ngIf="applicant.email"><p>Email: <br><b>{{applicant.email}}</b></p></div>-->
<!--                  <div *ngIf="applicant.birthdayDate && applicant.birthdayMonth && applicant.birthdayYear"><p>Birthday: <br>-->
<!--                    <b>{{applicant.birthdayDate}}-{{applicant.birthdayMonth}}-{{applicant.birthdayYear}}</b>-->
<!--                  </p></div>-->
<!--                  <div *ngIf="applicant.sin"><p>Sin: <br><b>{{applicant.sin}}</b></p></div>-->
<!--                  <div *ngIf="applicant.maritial"><p>Marital status: <br><b>{{applicant.maritial}}</b></p></div>-->
<!--                  <div *ngIf="applicant.canadian"><p>Canadian citizen? <br><b>{{applicant.canadian}}</b></p></div>-->
<!--                </div>-->
<!--                <div class="col-lg-4">-->
<!--                  <h6>Applicant employments</h6>-->
<!--                  <div *ngFor="let employment of lead_app_data.employments">-->
<!--                    <div *ngIf="employment.applicantID == applicant.id">-->
<!--                      <div *ngIf="employment.province"><p>Province: <br><b>{{employment.province}}</b></p></div>-->
<!--                      <div *ngIf="employment.city"><p>City: <br><b>{{employment.city}}</b></p></div>-->
<!--                      <div *ngIf="employment.postal"><p>Postal: <br><b>{{employment.postal}}</b></p></div>-->
<!--                      <div *ngIf="employment.name"><p>Employer Name: <br><b>{{employment.name}}</b></p></div>-->
<!--                      <div *ngIf="employment.type"><p>Income type: <br><b>{{employment.name}}</b></p></div>-->
<!--                      <div *ngIf="employment.title"><p>Job title: <br><b>{{employment.title}}</b></p></div>-->
<!--                      <div *ngIf="employment.addressMap"><p>Employer Address: <br><b>{{employment.addressMap}}</b></p></div>-->
<!--                      <div *ngIf="employment.phone"><p>Employer Phone: <br><b>{{employment.phone}}</b></p></div>-->
<!--                      <p>Length of Employment<br></p>-->
<!--                      <div *ngIf="employment.years"><p>Years: <br><b>{{employment.years}}</b></p></div>-->
<!--                      <div *ngIf="employment.months"><p>Months: <br><b>{{employment.months}}</b></p></div>-->
<!--                      <div *ngIf="employment.source"><p>Source: <br><b>{{employment.source}}</b></p></div>-->
<!--                      <div *ngIf="employment.backToWorkDate && employment.backToWorkMonths && employment.backToWorkYear">-->
<!--                        <p>Anticipated return to work date: <br><b>{{employment.backToWorkDate}}-{{employment.backToWorkMonths}}-{{employment.backToWorkYear}}</b></p>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-lg-4">-->
<!--                  <h6>Applicant address</h6>-->
<!--                  <div *ngFor="let address of lead_app_data.addresses">-->
<!--                    <div *ngIf="address.applicantID == applicant.id">-->
<!--                      <div *ngIf="address.province"><p>Province: <br><b>{{address.province}}</b></p></div>-->
<!--                      <div *ngIf="address.city"><p>City: <br><b>{{address.city}}</b></p></div>-->
<!--                      <div *ngIf="address.postal"><p>Postal: <br><b>{{address.postal}}</b></p></div>-->
<!--                      <div *ngIf="address.addressAddress"><p>Street: <br><b>{{address.addressAddress}}</b></p></div>-->
<!--                      <p>Time at Residence<br></p>-->
<!--                      <div *ngIf="address.years"><p>Years: <br><b>{{address.years}}</b></p></div>-->
<!--                      <div *ngIf="address.months"><p>Months: <br><b>{{address.months}}</b></p></div>-->
<!--                      <div *ngIf="address.status"><p>Status: <br><b>{{address.status}}</b></p></div>-->
<!--                      <div *ngIf="address.rent"><p>Rent: <br><b>{{address.rent}}</b></p></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-lg-4">-->
<!--                  <h6>Applicant other properties owned</h6>-->
<!--                  <div *ngFor="let property of lead_app_data.properties">-->
<!--                    <div *ngIf="property.applicantID == applicant.id">-->
<!--                      <div *ngIf="property.type"><p>Property type: <br><b>{{property.type}}</b></p></div>-->
<!--                      <div *ngIf="property.propertyAddress"><p>Property address: <br><b>{{property.propertyAddress}}</b></p></div>-->
<!--                      <div *ngIf="property.isMortgage"><p>Is this property mortgaged? <br><b>{{property.isMortgage}}</b></p></div>-->
<!--                      <div *ngIf="property.postal"><p>Postal: <br><b>{{property.postal}}</b></p></div>-->
<!--                      <div *ngIf="property.addressAddress"><p>Street: <br><b>{{property.addressAddress}}</b></p></div>-->
<!--                      <div *ngIf="property.balance"><p>Balance: <br><b>{{property.balance}}</b></p></div>-->
<!--                      <div *ngIf="property.frequency"><p>Payment Frequency: <br><b>{{property.frequency}}</b></p></div>-->
<!--                      <div *ngIf="property.payment"><p>Payment amount: <br><b>{{property.payment}}</b></p></div>-->
<!--                      <div *ngIf="property.maturityDate && property.maturityMonth && property.maturityYear">-->
<!--                        <p>Anticipated return to work date: <br><b>{{property.maturityDate}}-{{property.maturityMonth}}-{{property.maturityYear}}</b></p>-->
<!--                      </div>-->
<!--                      <div *ngIf="property.rate"><p>"Rate type: <br><b>{{property.rate}}</b></p></div>-->
<!--                      <div *ngIf="property.term"><p>Term type: <br><b>{{property.term}}</b></p></div>-->
<!--                      <div *ngIf="property.holder"><p>Mortgage Holder: <br><b>{{property.holder}}</b></p></div>-->
<!--                      <div *ngIf="property.interest"><p>Mortgage Interest Rate: <br><b>{{property.interest}}</b></p></div>-->
<!--                      <div *ngIf="property.tax"><p>Tax (yearly): <br><b>{{property.tax}}</b></p></div>-->
<!--                      <div *ngIf="property.condo"><p>Monthly Strata Fee: <br><b>{{property.condo}}</b></p></div>-->
<!--                      <div *ngIf="property.heat"><p>Square footage: <br><b>{{property.heat}}</b></p></div>-->
<!--                      <div *ngIf="property.rent"><p>Monthly Rental Income: <br><b>{{property.rent}}</b></p></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-lg-4">-->
<!--                  <h6>Applicant assets</h6>-->
<!--                  <div *ngFor="let asset of lead_app_data.assets">-->
<!--                    <div *ngIf="asset.applicantID == applicant.id">-->
<!--                      <div *ngIf="asset.saving"><p>Personal saving account: <br><b>{{asset.saving}}</b></p></div>-->
<!--                      <div *ngIf="asset.investment"><p>Investments, RRSP, LIRA and GIC: <br><b>{{asset.investment}}</b></p></div>-->
<!--                      <div *ngIf="asset.approximate"><p>Approximate value of owned vehicles: <br><b>{{asset.approximate}}</b></p></div>-->
<!--                      <div *ngIf="asset.gifts"><p>Investments, RRSP, LIRA and GIC: <br><b>{{asset.gifts}}</b></p></div>-->
<!--                      <div *ngIf="asset.investment"><p>'Gifts' from a family member for your down payment: <br><b>{{asset.investment}}</b></p></div>-->
<!--                      <div *ngIf="asset.description_1 && asset.slider_1"><p><b>{{asset.description_1}}: {{asset.slider_1}}</b></p></div>-->
<!--                      <div *ngIf="asset.description_2 && asset.slider_2"><p><b>{{asset.description_2}}: {{asset.slider_2}}</b></p></div>-->
<!--                      <div *ngIf="asset.description_3 && asset.slider_3"><p><b>{{asset.description_3}}: {{asset.slider_3}}</b></p></div>-->
<!--                      <div *ngIf="asset.description_4 && asset.slider_4"><p><b>{{asset.description_4}}: {{asset.slider_4}}</b></p></div>-->
<!--                      <div *ngIf="asset.description_5 && asset.slider_5"><p><b>{{asset.description_5}}: {{asset.slider_5}}</b></p></div>-->
<!--                      <div *ngIf="asset.description_6 && asset.slider_6"><p><b>{{asset.description_6}}: {{asset.slider_6}}</b></p></div>-->
<!--                      <div *ngIf="asset.description_7 && asset.slider_7"><p><b>{{asset.description_7}}: {{asset.slider_7}}</b></p></div>-->
<!--                      <div *ngIf="asset.description_8 && asset.slider_8"><p><b>{{asset.description_8}}: {{asset.slider_8}}</b></p></div>-->
<!--                      <div *ngIf="asset.description_9 && asset.slider_9"><p><b>{{asset.description_9}}: {{asset.slider_9}}</b></p></div>-->
<!--                      <div *ngIf="asset.description_10 && asset.slider_10"><p><b>{{asset.description_10}}: {{asset.slider_10}}</b></p></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-lg-4">-->
<!--                  <h6>Applicant incomes</h6>-->
<!--                  <div *ngFor="let income of lead_app_data.incomes">-->
<!--                    <div *ngIf="income.applicantID == applicant.id">-->
<!--                      <div *ngIf="income.type && income.saving"><p><b>{{income.type}}: {{income.saving}}</b></p></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--          </div>-->
        </div>
<!--        <div class="row">-->
<!--          <div class="col-12"><h5 class="pt-5">Additional info</h5></div>-->
<!--          <div class="col-47">-->
<!--            <div class="form-group d-flex">-->
<!--              <div class="field-title">Referral</div>-->
<!--              <div *ngIf="isBlocked; then isBlockedBlock12 else notBlockedBlock12"></div>-->
<!--              <ng-template #isBlockedBlock12><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.referral" inputDisabled="disabled"></editable-input-cmp></div></ng-template>-->
<!--              <ng-template #notBlockedBlock12><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.referral"></editable-input-cmp></div></ng-template>-->
<!--            </div>-->
<!--            <div class="form-group d-flex">-->
<!--              <div class="field-title">Comment</div>-->
<!--              <div *ngIf="isBlocked; then isBlockedBlock13 else notBlockedBlock13"></div>-->
<!--              <ng-template #isBlockedBlock13><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.comment" inputDisabled="disabled"></editable-input-cmp></div></ng-template>-->
<!--              <ng-template #notBlockedBlock13><div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.comment"></editable-input-cmp></div></ng-template>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-47">-->
<!--            <div class="form-group d-flex">-->
<!--              <div class="field-title">Created date</div>-->
<!--              <div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.created_date" inputDisabled="disabled"></editable-input-cmp></div>-->
<!--            </div>-->
<!--            <div class="form-group d-flex">-->
<!--              <div class="field-title">Last modification date</div>-->
<!--              <div class="field-input"><editable-input-cmp [inputClass]="inputClass" [inputValue]="lead.updated_date" inputDisabled="disabled"></editable-input-cmp></div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-12">-->
<!--            <div class="form-group">-->
<!--              <div>Question</div>-->
<!--              <textarea class="form-control" cols="30" rows="5" disabled>{{(lead$ | async).question}}</textarea>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div *ngIf="isBlocked; then isBlockedBlock14"></div>-->
<!--        <ng-template #isBlockedBlock14>-->
<!--          <div class="row">-->
<!--            <div class="col-12">-->
<!--              <div class="form-group text-right">-->
<!--                <button class="btn btn-primary btn-round" (click)="setUserLead()">Accept Lead</button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-template>-->
      </span>
      <span class="tabData {{activeTab == 2 ? 'active' : ''}}">
<!--        <div *ngFor="let history of lead_history_data" class="col-12 p-3">-->
<!--          <div class="historyBlock" [innerHtml]="history"></div>-->
<!--        </div>-->
        <div *ngFor="let history of lead_history_data" id="timeLineContainerDataDV" class="pR" style="">
            <div class="timeLineHistInner pR">
              <div class="timeLineHistDate pB20">{{transformDate(history.key)}}</div>
              <ul *ngFor="let event of history.events" class="timeLineHistDataList">
                 <li class="{{event.EVENT_TYPE}} Leads ">
                    <span class="timeLineHistTime crm-font-regular f13 fL">{{transformTime(event.DATE_CREATE)}}</span>
                    <span class="crm-font-regular f15 fL timeLineHistCont pR">
                       <div class="pulsefldupd">
                          <div [innerHTML]="event.EVENT"></div>
                       </div>
                      <div class='userName' *ngIf='event.EVENT_TYPE == "timeline_updated"'><span class="tl_by">by </span> <span class="f13" purpose="showUserBC" data-params="{&quot;userId&quot;:&quot;11550000000594007&quot;}">{{event.USER_NAME}}</span></div>
                      <div class='userName' *ngIf='event.EVENT_TYPE == "timeline_added"'><span class="tl_by">from </span> <span class="f13" purpose="showUserBC" data-params="{&quot;userId&quot;:&quot;11550000000594007&quot;}">{{lead.source}} <span *ngIf!='lead.referral'>(<a
                        href='lead.referral'>{{lead.referral}})</a></span></span></div>
                      <div class='userName' *ngIf='event.EVENT_TYPE == "timeline_TaskAssigned"'><span class="tl_by">to </span> <span class="f13" purpose="showUserBC" data-params="{&quot;userId&quot;:&quot;11550000000594007&quot;}">{{event.USER_NAME}}</span></div>
                    </span>
                 </li>
              </ul>
            </div>
          </div>
      </span>
    </div>
  </div>
</form>
