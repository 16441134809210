import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadStatusSelectComponent } from './leadStatusSelect.components';

@NgModule({
  imports: [ CommonModule ],
  declarations: [ LeadStatusSelectComponent ],
  exports: [ LeadStatusSelectComponent ]
})

export class LeadStatusSelectModule {}
