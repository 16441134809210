import {Injectable} from '@angular/core'
import {HttpClient, HttpErrorResponse} from '@angular/common/http'
import {Observable, throwError} from 'rxjs'
import {catchError, map} from 'rxjs/operators'

import {LeadInterface} from 'src/app/lead/types/lead.interface'

import {environment} from 'src/environments/environment'
import {PersistanceService} from 'src/app/shared/services/persistance.service'
import { ActivatedRoute } from '@angular/router'

@Injectable()
export class LeadService {
  constructor(private http: HttpClient, private persistanceService: PersistanceService, private _Activatedroute:ActivatedRoute) {
  }


  getLead(): Observable<LeadInterface> {
    let id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    const url = environment.apiUrl + '/get_lead.php'
    const params = {
      user: {
        email: this.persistanceService.get('email'),
        token: this.persistanceService.get('accessToken'),
        leadID: id
      }
    }
      return this.http.post<LeadInterface>(url, params).pipe(
        map(resp => resp),
        catchError((error: HttpErrorResponse) => throwError(error))
      )
  }

  updateLead(leadId: number, field: string, value: string): Observable<LeadInterface> {
    console.log('updateLeadService')
    let id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    const url = environment.apiUrl + '/update_lead.php'
    const params = {
      user: {
        email: this.persistanceService.get('email'),
        token: this.persistanceService.get('accessToken'),
        leadID: id,
        field: field,
        value: value
      }
    }
    console.log(params)
    return this.http.post<LeadInterface>(url, params).pipe(
      map(resp => resp),
      catchError((error: HttpErrorResponse) => throwError(error))
    )
  }
}
