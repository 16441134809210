<div class="auth-page">
  <div class="container page">
    <div class="row">
      <div class="col-12">
        <h1 class="text-xs-center">Sign Up</h1>
        <p class="text-xs-center">
          <a [routerLink]="['/login']">Have an account?</a>
        </p>
        <mc-backend-error-messages
          *ngIf="backendErrors$ | async"
          [backendErrors]="backendErrors$ | async"
        ></mc-backend-error-messages>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <fieldset class='row'>
            <div class='col-lg-6'>
              <fieldset class="form-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="First Name"
                  formControlName="firstname"
                />
              </fieldset>
              <fieldset class="form-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Middle Name (if applicable)"
                  formControlName="middlename"
                />
              </fieldset>
              <fieldset class="form-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Last Name"
                  formControlName="lastname"
                />
              </fieldset>
              <fieldset class="form-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Brokerage name"
                  formControlName="brokeragename"
                />
              </fieldset>
              <fieldset class="form-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Brokerage address"
                  formControlName="brokerageaddress"
                />
              </fieldset>
            </div>
            <div class='col-lg-6'>
              <fieldset class="form-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="License number"
                  formControlName="licensenumber"
                />
              </fieldset>
              <fieldset class="form-group">
                <select class="custom-select" formControlName="province">
                  <option value=''>Choose the province</option>
                  <option *ngFor="let province of Provinces" [value]="province">{{province}}</option>
                </select>
              </fieldset>
              <fieldset class="form-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Phone"
                  formControlName="phone"
                />
              </fieldset>
              <fieldset class="form-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Email"
                  formControlName="email"
                />
              </fieldset>
              <fieldset class="form-group">
                <input
                  type="password"
                  class="form-control form-control-lg"
                  placeholder="Password"
                  formControlName="password"
                />
              </fieldset>
            </div>
            <div class='col-12 text-right'>
              <button
                class="btn btn-lg btn-primary pull-xs-right"
                type="submit"
                [disabled]="isSubmitting$ | async"
              >
                Sign up
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>
