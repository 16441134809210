import {createAction, props} from '@ngrx/store'

import {ActionTypes} from 'src/app/openLeads/store/actionTypes'
import { LeadsInterface } from 'src/app/shared/types/leads.interface'

export const getOpenLeadsAction = createAction(
  ActionTypes.GET_OPEN_LEADS
)

export const getOpenLeadsSuccessAction = createAction(
  ActionTypes.GET_OPEN_LEADS_SUCCESS,
  props<LeadsInterface>()
)

export const getOpenLeadsFailureAction = createAction(
  ActionTypes.GET_OPEN_LEADS_FAILURE
)
