import {Injectable} from '@angular/core'
import {createEffect, Actions, ofType} from '@ngrx/effects'
import {map, catchError, switchMap} from 'rxjs/operators'
import {HttpErrorResponse} from '@angular/common/http'
import {of} from 'rxjs'

import {AuthService} from 'src/app/auth/services/auth.service'
import {CurrentUserInterface} from 'src/app/shared/types/currentUser.interface'
import {
  updateCurrentUserFailureAction,
  updateCurrentUserSuccessAction,
  updateCurrentUserAction
} from 'src/app/auth/store/actions/updateCurrentUser.action'

import { sendMessgaeService } from 'src/app/shared/modules/messageBox/services/sendMessgae.service';
import {FormBuilder} from '@angular/forms';
import {Store} from '@ngrx/store';

@Injectable()
export class UpdateCurrentUserEffect {

  updateCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCurrentUserAction),
      switchMap(({currentUserInput}) => {
        return this.authService.updateCurrentUser(currentUserInput).pipe(
          map((currentUser: CurrentUserInterface) => {
            this.sms.sendMessage("Updated", "success");
            return updateCurrentUserSuccessAction({currentUser})
          }),

          catchError((errorResponse: HttpErrorResponse) => {
            this.sms.sendMessage("Error", "error");
            return of(
              updateCurrentUserFailureAction({
                errors: errorResponse.error.errors
              })
            )
          })
        )
      })
    )
  )

  constructor(private actions$: Actions, private authService: AuthService, private sms: sendMessgaeService) {}
}
