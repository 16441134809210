<mc-messageBox></mc-messageBox>
<div class="wrapper">
  <ng-container *ngIf="isLoggedIn$ | async">
    <div class="sidebar" [ngClass]="{'open' : showSidebarVal}" data-color="black" data-active-color="danger">
      {{showSidebarVal}}
      <sidebar-cmp (hideSidebarEvent)=hideSidebar()></sidebar-cmp>
    </div>
    <div class="main-panel">
      <mc-topbar (showSidebarEvent)=showSidebar($event) [sidebarShowed]="showSidebarVal"></mc-topbar>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
  <!--    <footer-cmp></footer-cmp>-->
    </div>
  </ng-container>
  <ng-container *ngIf="isAnonymous$ | async">

    <mc-topbar (showSidebarEvent)=showSidebar($event) [sidebarShowed]="showSidebarVal"></mc-topbar>
    <div class="content">
      <router-outlet>
      </router-outlet>
    </div>
  </ng-container>
</div>
<!--<fixedplugin-cmp></fixedplugin-cmp>-->
