import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditableTextareaComponent } from './editableTextarea.components';

@NgModule({
  imports: [ CommonModule ],
  declarations: [ EditableTextareaComponent ],
  exports: [ EditableTextareaComponent ]
})

export class EditableTextareaModule {}
