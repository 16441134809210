import {LeadsInterface} from 'src/app/shared/types/leads.interface'
import {createReducer, on, Action} from '@ngrx/store'
import {
  getLeadsAction,
  getLeadsSuccessAction,
  getLeadsFailureAction
} from 'src/app/crm/store/actions/getLeads.action'

const initialState: LeadsInterface = {
  leads: null
}

const leadsReducers = createReducer(
  initialState,
  on(
    getLeadsAction,
    (state, action): LeadsInterface => ({
      ...state,
      leads: null
    })
  ),
  on(
    getLeadsSuccessAction,
    (state, action): LeadsInterface => ({
      ...state,
      leads: action.leads
    })
  ),
  on(
    getLeadsFailureAction,
    (state, action): LeadsInterface => ({
      ...state,
      leads: null
    })
  )
)

export function reducers(state: LeadsInterface, action: Action) {
  return leadsReducers(state, action)
}
