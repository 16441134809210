import {NgModule} from '@angular/core'
import {Routes, RouterModule} from '@angular/router'
import {DashboardComponent} from './dashboard/components/dashboard/dashboard.component'
import {AuthGuard} from './shared/services/authGurad.service'
import {LoginComponent} from './auth/components/login/login.component'
import {RegisterComponent} from './auth/components/register/register.component'
import {AuthGuardAnonymous} from './shared/services/authGuardAnonymous.service'
import {ProfileComponent} from './profile/components/profile/profile.component'
import {CrmComponent} from './crm/components/crm/crm.component'
import {OpenLeadsComponent} from './openLeads/components/openLeads/openLeads.component'
import {LeadComponent} from './lead/components/lead/lead.component'
import {SettingsComponent} from './settings/components/settings/settings.component'
import {PackagesComponent} from './packages/components/packages/packages.component'
import {WidgetsComponent} from './widgets/components/widgets/widgets.component'

import {MortgageApplicationComponent} from './calcs/mortgage-application/components/mortgage-application/mortgage-application.component'
import {CanadianMortgageCalculatorComponent} from './calcs/canadian-mortgage-calculator/components/canadian-mortgage-calculator/canadian-mortgage-calculator.component'
import {CurrentMortgageCalculatorComponent} from './calcs/current-mortgage-calculator/components/current-mortgage-calculator/current-mortgage-calculator.component'
import {LandTransferCalculatorComponent} from './calcs/land-transfer-tax-calculator/components/land-transfer-tax-calculator/land-transfer-tax-calculator.component'
import {MortgageRatesComponent} from './calcs/mortgage-rates-calculator/components/mortgage-rates-calculator/mortgage-rates-calculator.component'
import {MortgageAffordabilityCalculatorComponent} from './calcs/mortgage-affordability-calculator/components/mortgage-affordability-calculator/mortgage-affordability-calculator.component'
import {PrequalificationApplicationComponent} from './calcs/prequalification-application/components/prequalification-application/prequalification-application.component'
import {PrequalificationApplicationPurchaseComponent} from './calcs/prequalification-application-purchase/components/prequalification-application/prequalification-application-purchase.component'
import {PrequalificationApplicationrRefinanceComponent} from './calcs/prequalification-application-refinance/components/prequalification-application/prequalification-application-refinance.component'
import {RatesByLenderCalculatorComponent} from './calcs/rates-by-lender-calculator/components/rates-by-lender-calculator/rates-by-lender-calculator.component'
import {StepsFormCalculatorComponent} from './calcs/steps-form-calculator/components/steps-form-calculator/steps-form-calculator.component'

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
]

const routesChild: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'crm',
    component: CrmComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'openLeads',
    component: OpenLeadsComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'lead',
    component: LeadComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'lead/:leadId',
    component: LeadComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'packages',
    component: SettingsComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'widgets',
    component: WidgetsComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'mortgage-application',
    component: MortgageApplicationComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'canadian-mortgage-calculator',
    component: CanadianMortgageCalculatorComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'current-mortgage-calculator',
    component: CurrentMortgageCalculatorComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'land-transfer-calculator',
    component: LandTransferCalculatorComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'mortgage-rates-calculator',
    component: MortgageRatesComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'mortgage-affordability-calculator',
    component: MortgageAffordabilityCalculatorComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'prequalification-application',
    component: PrequalificationApplicationComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'prequalification-application-purchase',
    component: PrequalificationApplicationPurchaseComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'prequalification-application-refinance',
    component: PrequalificationApplicationrRefinanceComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'rates-by-lender-calculator',
    component: RatesByLenderCalculatorComponent,
    canActivate: [AuthGuardAnonymous]
  },
  {
    path: 'steps-form-calculator',
    component: StepsFormCalculatorComponent,
    canActivate: [AuthGuardAnonymous]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes), RouterModule.forChild(routesChild)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
