import {Component, Inject, Input, OnInit, Renderer2} from '@angular/core'
import {DOCUMENT} from '@angular/common'

@Component({
  selector: 'canadian-mortgage-application-cmp',
  templateUrl: 'canadian-mortgage-calculator.component.html'
})

export class CanadianMortgageCalculatorComponent implements OnInit {
  // @Input() selectID: string;
  public preloadingClass: string;
  public selectID = "calcDefaultProvider";
  master = 'Master';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2
  ) {

  }

  ngOnInit() {
    let self = this;
    this.preloadingClass = "preloader";
    const renderer2 = this.renderer2;

    const textScript11 = this.renderer2.createElement('script');
    textScript11.src = 'https://mortgages.ratepal.ca/js/widget_application_form/select2.full.min.js';

    const srcScript = this.renderer2.createElement('script');
    srcScript.type = 'text/javascript';
    srcScript.text = `
      (function() {
        jscolor.installByClassName("jscolor")
      }());
    `;

    const textScript13 = this.renderer2.createElement('script');
    textScript13.src = 'https://mortgages.ratepal.ca/js/widget/script_broker.js';

    const textScript2 = this.renderer2.createElement('script');
    // textScript2.src = 'https://mortgages.ratepal.ca/api/canadian-mortgage-calculator/load_brokers.js';
    setTimeout(function () {
      renderer2.appendChild(this.document.body, textScript11);
      // renderer2.appendChild(this.document.body, textScript12);
    }, 0)
    setTimeout(function () {
      // renderer2.appendChild(this.document.body, textScript11);
      renderer2.appendChild(this.document.body, textScript13);
      renderer2.appendChild(this.document.body, textScript2);
      renderer2.appendChild(this.document.body, srcScript);
    }, 500)

    setTimeout(function () {
      self.preloadingClass = "";
    }, 1000)
  }
}
