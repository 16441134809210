import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'widgets-cmp',
  templateUrl: 'widgets.component.html',
  styleUrls: ['./widgets.component.scss']
})


export class WidgetsComponent implements OnInit{
  ngOnInit(){

  }

  ngAfterViewInit(){
    setTimeout(function (){
      console.log("here")
      var cards = document.getElementById("widgets").getElementsByClassName("card");
      for (var i = 0; i < cards.length; i++) {
        let card = cards[i];
        setTimeout(function (){
          card.classList.remove("unloaded");
          card.classList.add("loading");
          setTimeout(function (){
            card.classList.remove("loading");
          }, 500)
        }, i * 100)
      }
    }, 0)
  }
}

