import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {StoreModule} from '@ngrx/store'
import {EffectsModule} from '@ngrx/effects'

import {RouterModule, Routes} from '@angular/router'


import {reducers} from 'src/app/auth/store/reducers'
// import {AuthService} from 'src/app/auth/services/auth.service'
// import {RegisterEffect} from 'src/app/auth/store/effects/register.effect'
import {BackendErrorMessagesModule} from 'src/app/shared/modules/backendErrorMessages/backendErrorMessages.module'
// import {PersistanceService} from 'src/app/shared/services/persistance.service'
// import {LoginEffect} from 'src/app/auth/store/effects/login.effect'
// import {LoginComponent} from 'src/app/auth/components/login/login.component'
import {GetCurrentUserEffect} from 'src/app/auth/store/effects/getCurrentUser.effect'
import {CurrentMortgageCalculatorComponent} from './components/current-mortgage-calculator/current-mortgage-calculator.component'
// import {LoginComponent} from '../auth/components/login/login.component'
// import {AuthGuardAnonymous} from '../shared/services/authGuardAnonymous.service'
import {ReactiveFormsModule} from '@angular/forms'

import {PlansSelectModule} from 'src/app/shared/modules/plansSelect/plansSelect.module'
import {BankTypesSelectModule} from 'src/app/shared/modules/bankTypesSelect/bankTypesSelect.module'
import {PersistanceService} from 'src/app/shared/services/persistance.service'

const routes = [
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('profile', reducers),
    ReactiveFormsModule,
    PlansSelectModule,
    BankTypesSelectModule,
    EffectsModule.forFeature([
      // RegisterEffect,
      // LoginEffect,
      GetCurrentUserEffect
    ]),
    CommonModule
    // BackendErrorMessagesModule
  ],
  declarations: [CurrentMortgageCalculatorComponent]
})
export class CurrentMortgageCalculatorModule {}
