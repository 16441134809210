import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {StoreModule} from '@ngrx/store'
import {EffectsModule} from '@ngrx/effects'

import {RouterModule, Routes} from '@angular/router'


import {reducers} from 'src/app/auth/store/reducers'
import {BackendErrorMessagesModule} from 'src/app/shared/modules/backendErrorMessages/backendErrorMessages.module'
import {GetCurrentUserEffect} from 'src/app/auth/store/effects/getCurrentUser.effect'
import {MortgageRatesComponent} from './components/mortgage-rates-calculator/mortgage-rates-calculator.component'
import {ReactiveFormsModule} from '@angular/forms'

import {PersistanceService} from 'src/app/shared/services/persistance.service'
import {BanksSelectModule} from 'src/app/shared/modules/banksSelect/banksSelect.module'
import {PlansSelectModule} from 'src/app/shared/modules/plansSelect/plansSelect.module'
import {BankTypesSelectModule} from 'src/app/shared/modules/bankTypesSelect/bankTypesSelect.module'

const routes = [
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('profile', reducers),
    ReactiveFormsModule,
    BanksSelectModule,
    PlansSelectModule,
    BankTypesSelectModule,
    EffectsModule.forFeature([
      GetCurrentUserEffect
    ]),
    CommonModule
  ],
  declarations: [MortgageRatesComponent]
})
export class MortgageRatesCalculatorModule {}
