import {Component, Inject, OnInit, Renderer2} from '@angular/core'
import {DOCUMENT} from '@angular/common'
import { Router } from '@angular/router';

@Component({
  selector: 'mortgage-application-cmp',
  templateUrl: 'mortgage-application.component.html',
  styleUrls: ['./mortgage-application.component.scss']
})

// <link href="https://fonts.googleapis.com/css?family=Roboto+Slab:400,700" rel="stylesheet">
// <link rel="stylesheet" href="css/widget_steps/bootstrap-grid.css">
// <link rel="stylesheet" href="css/widget_steps/select2.min.css">
// <link rel="stylesheet" href="css/widget_steps/styles.css">

export class MortgageApplicationComponent implements OnInit{
  public preloadingClass: string;
  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2
  ) {

  }
  refreshPage() {
    this.document.defaultView.location.reload();
  }
  ngOnInit(){
    // this.refreshPage();
    // this.router.routeReuseStrategy.shouldReuseRoute = () => true;
    let self = this;
    this.preloadingClass = "preloader";
    const renderer2 = this.renderer2;
    // const textScript = this.renderer2.createElement('script');
    // textScript.src = 'https://mortgages.ratepal.ca/api/allScripts.js';
    // this.renderer2.appendChild(this.document.body, textScript);
    //
    // const textScript1 = this.renderer2.createElement('script');
    // textScript1.src = 'https://mortgages.ratepal.ca/js/widget/jquery-3.3.1.min.js';
    // this.renderer2.appendChild(this.document.body, textScript1);
    //
    const textScript11 = this.renderer2.createElement('script');
    textScript11.src = 'https://mortgages.ratepal.ca/js/widget_application_form/select2.full.min.js';
    //
    // const textScript12 = this.renderer2.createElement('script');
    // textScript12.src = 'https://mortgages.ratepal.ca/js/widget_application_form/jscolor.js';
    const srcScript = this.renderer2.createElement('script');
    srcScript.type = 'text/javascript';
    srcScript.text = `
      (function() {
        jscolor.installByClassName("jscolor")
      }());
    `;

    const textScript13 = this.renderer2.createElement('script');
    textScript13.src = 'https://mortgages.ratepal.ca/js/widget_application_form/script_broker.js';

    const textScript2 = this.renderer2.createElement('script');
    textScript2.src = 'https://mortgages.ratepal.ca/api/mortgage-application/load_brokers.js?v='+Date.now();

    setTimeout(function(){
      renderer2.appendChild(this.document.body, textScript11);
      // renderer2.appendChild(this.document.body, textScript12);
    }, 0)

    setTimeout(function(){
      // renderer2.appendChild(this.document.body, textScript11);
      renderer2.appendChild(this.document.body, textScript13);
      renderer2.appendChild(this.document.body, textScript2);
      renderer2.appendChild(this.document.body, srcScript);
    }, 500)

    setTimeout(function (){
      self.preloadingClass = "";
    }, 1000)


    // <script src="js/widget_application_form/jquery-3.3.1.min.js"></script>
    // <script src="js/widget_application_form/select2.full.min.js"></script>
    // <script src="js/widget_application_form/jscolor.js"></script>
    // <script src="js/widget_application_form/script.js"></script>

  }
}
