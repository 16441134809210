
<div>
  <form class="row" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="col-12">
      <div id="infoCard" class="card card-user unloaded">
        <div class="card-header">
          <h5 class="card-title">Settings</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 pr-1">
              <div class="form-group">
                <label>Custom App URL</label>
                <input type="text" class="form-control" placeholder="URL" [value]="(currentUser$ | async).custom_app" formControlName="custom_app">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="update ml-auto mr-auto">
              <button type="submit" class="btn btn-primary btn-round" [disabled]="form.invalid || (isSubmitting$ | async)">Update Settings</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>



