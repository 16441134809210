import {Component, EventEmitter, Input, OnInit, OnDestroy, Output} from '@angular/core'
import {select, Store} from '@ngrx/store'

import {getCurrentUserAction} from 'src/app/auth/store/actions/getCurrentUser.action'

import {
  isLoggedInSelector,
  isAnonymousSelector,
  currentUserSelector
} from 'src/app/auth/store/selectors'
import {Observable, Subscription} from 'rxjs'
import {CurrentUserInterface} from './shared/types/currentUser.interface'

import {TopBarComponent} from"src/app/shared/modules/topBar/components/topBar/topBar.component"
import {ProfileComponent} from"src/app/profile/components/profile/profile.component"
import { sendMessgaeService } from 'src/app/shared/modules/messageBox/services/sendMessgae.service';

@Component({
  selector: ' app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoggedIn$: Observable<boolean>
  isAnonymous$: Observable<boolean>
  currentUser$: Observable<CurrentUserInterface | null>

  @Input() imageSrc = <any> "ddd"
  @Output() userLogo = "ddsadsa";
  showSidebarVal: boolean = false


  constructor(private store: Store,) {}


  ngOnInit(): void {
    this.store.dispatch(getCurrentUserAction())
    this.isLoggedIn$ = this.store.pipe(select(isLoggedInSelector))
    this.isAnonymous$ = this.store.pipe(select(isAnonymousSelector))
    this.currentUser$ = this.store.pipe(select(currentUserSelector))
  }

  ngOnDestroy(): void{

  }

  showSidebar(newVal: boolean) {
    console.log("showSidebar - APP")
    this.showSidebarVal = newVal;
  }

  hideSidebar() {
    console.log("hideSidebar - APP")
    this.showSidebarVal = false;
  }
}
