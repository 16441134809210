import {Component, Input} from '@angular/core'

@Component({
  selector: 'province-select',
  templateUrl: './provinceSelect.component.html'
})
export class ProvinceSelectComponent {

  Provinces: any = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon'
  ]

  @Input() userProvince: string
}
