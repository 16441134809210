import {Component, Inject, OnInit, Renderer2} from '@angular/core'
import {DOCUMENT} from '@angular/common'

@Component({
  selector: 'prequalification-application-refinance-cmp',
  templateUrl: 'prequalification-application-refinance.component.html',
  styleUrls: ['./prequalification-application-refinance.component.scss']
})

export class PrequalificationApplicationrRefinanceComponent implements OnInit{
  public preloadingClass: string;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2
  ) {

  }

  ngOnInit(){
    const time = Date.now();
    let self = this;
    this.preloadingClass = "preloader";
    const renderer2 = this.renderer2;
      (window as any).isRefinance = true

    const srcScript = this.renderer2.createElement('script');
    srcScript.type = 'text/javascript';
    srcScript.text = `
      (function() {
        jscolor.installByClassName("jscolor")
      }());
    `;

    const textScript11 = this.renderer2.createElement('script');
    textScript11.src = 'https://mortgages.ratepal.ca/js/widget_table/select2.full.min.js';

    const textScript13 = this.renderer2.createElement('script');
    textScript13.src = 'https://mortgages.ratepal.ca/js/widget_prequalification/script_broker.js?v='+time;
    setTimeout(function(){
      renderer2.appendChild(this.document.body, textScript11);
    }, 0)

    setTimeout(function(){
      // renderer2.appendChild(this.document.body, textScript11);
      renderer2.appendChild(this.document.body, textScript13);
      renderer2.appendChild(this.document.body, srcScript);
    }, 500)

    setTimeout(function (){
      self.preloadingClass = "";
    }, 1000)
  }

  ngOnDestroy() {
    (window as any).isRefinance = false
  }
}
