import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BankTypesSelectComponent } from './bankTypesSelect.components';

@NgModule({
  imports: [ CommonModule ],
  declarations: [ BankTypesSelectComponent ],
  exports: [ BankTypesSelectComponent ]
})

export class BankTypesSelectModule {}
