<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Open Leads</h4>
      </div>
      <div class="card-body">
        <div class="table-responsive col-12">
          <table #dataTable  class="row-border hover" style="width:100%">
<!--            <thead class=" text-primary">-->
<!--              <tr>-->
<!--                <th *ngFor="let header of tableData.headerRow">{{header}}</th>-->
<!--              </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--              <tr *ngFor="let row of tableData.dataRows">-->
<!--                <td>-->
<!--                  {{row.ID}}-->
<!--                </td>-->
<!--                <td>-->
<!--                  {{row.USER_ID}}-->
<!--                </td>-->
<!--                <td>-->
<!--                  {{row.NAME}}-->
<!--                </td>-->
<!--                <td>-->
<!--                  {{row.DATE_CREATE}}-->
<!--                </td>-->
<!--                <td>-->
<!--                  {{row.DATE_UPDATE}}-->
<!--                </td>-->
<!--              </tr>-->
<!--            </tbody>-->
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
