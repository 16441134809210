import { HttpClient, HttpParams } from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core'


@Component({
  selector: 'lead-status-select-cmp',
  templateUrl: 'leadStatusSelect.component.html',
  styleUrls: ['leadStatusSelect.component.scss']
})

export class LeadStatusSelectComponent implements OnInit{
  @Input('selectID') selectID = '';
  public LeadStatuses: any

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit() {
    const formData = new FormData();
    formData.append('api', "getLeadStatuses");
    formData.append('json', "true");


    this.httpClient.post('https://mortgages.ratepal.ca/api/backend.php', formData).subscribe(data => this.LeadStatuses = data);
  }

}
