import {Injectable} from '@angular/core'
import {createEffect, Actions, ofType} from '@ngrx/effects'
import {map, catchError, switchMap} from 'rxjs/operators'
import {Observable, of} from 'rxjs'
import { ActivatedRoute } from '@angular/router';
import {LeadService} from 'src/app/lead/services/lead.service'

import {LeadInterface} from 'src/app/lead/types/lead.interface'

import {PersistanceService} from 'src/app/shared/services/persistance.service'
import { sendMessgaeService } from 'src/app/shared/modules/messageBox/services/sendMessgae.service';
import {
  getLeadAction,
  getLeadSuccessAction,
  getLeadFailureAction,
  updateLeadAction,
  updateLeadFailureAction,
  updateLeadSuccessAction
} from 'src/app/lead/store/actions/getLead.action'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class GetLeadEffect {
  getLead = createEffect(() =>
    this.actions$.pipe(
      ofType(getLeadAction),
      switchMap(() => {
        const token = this.persistanceService.get('accessToken')
        let leadID
        console.log('start')
        if (!token) {
          return of(getLeadFailureAction())
        }
        return this.leadService.getLead().pipe(
          map((data: LeadInterface) => {
            console.log('success')
            return getLeadSuccessAction(data)
          }),

          catchError(() => {
            console.log('error')
            return of(getLeadFailureAction())
          })
        )
      })
    )
  )

  updateLead = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLeadAction),
      map(action => action.payload),
      switchMap((payload) => {
        const token = this.persistanceService.get('accessToken')
        let leadID
        if (!token) {
          this.sms.sendMessage("Error", "error");
          return of(updateLeadFailureAction())
        }
        return this.leadService.updateLead(payload.id, payload.field, payload.value).pipe(
          map((data: LeadInterface) => {
            this.sms.sendMessage("Saved", "success");
            return updateLeadSuccessAction(data)
          }),

          catchError(() => {
            this.sms.sendMessage("Error", "error");
            return of(updateLeadFailureAction())
          })
        )
      })
    )
  )

  constructor(
    private actions$: Actions,
    private leadService: LeadService,
    private persistanceService: PersistanceService,
    private _Activatedroute:ActivatedRoute,
    private sms : sendMessgaeService
  ) {}
}


// export class GetLeadsEffect {
//   constructor(private http: HttpClient) {
//     console.log("GetLeadsEffect")
//   }
//
//   getLeads$(): string {
//     return "hello"
//   }
//
// }
