import {createFeatureSelector, createSelector} from '@ngrx/store'
import {AppStateInterface} from 'src/app/shared/types/appState.interface'
import {LeadsInterface} from 'src/app/shared/types/leads.interface'

export const leadsFeatureSelector = createFeatureSelector<
  AppStateInterface,
  LeadsInterface
  >('openLeads')

export const getLeadsSelector = createSelector(
  leadsFeatureSelector,
  (leadsState: LeadsInterface) => {
    return leadsState
  }
)
