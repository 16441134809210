import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {StoreModule} from '@ngrx/store'
import {EffectsModule} from '@ngrx/effects'

import { DataTablesModule } from "angular-datatables";

import {RouterModule, Routes} from '@angular/router'

import {LeadsInterface} from 'src/app/shared/types/leads.interface'

import {LeadsService} from 'src/app/shared/services/leads.service'

import {reducers} from 'src/app/openLeads/store/reducers'
import {GetLeadsEffect} from 'src/app/openLeads/store/effects/getOpenLeads.effect'

import {OpenLeadsComponent} from './components/openLeads/openLeads.component'


const routes = [
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    DataTablesModule,
    StoreModule.forFeature('openLeads', reducers),
    EffectsModule.forFeature([
      GetLeadsEffect
    ])
  ],
  declarations: [OpenLeadsComponent],
  providers: [LeadsService, GetLeadsEffect]
})
export class OpenLeadsModule {}
