import {Injectable} from '@angular/core'
import {createEffect, Actions, ofType} from '@ngrx/effects'
import {map, catchError, switchMap} from 'rxjs/operators'
import {Observable, of} from 'rxjs'

import {LeadsService} from 'src/app/shared/services/leads.service'

import {LeadsInterface} from 'src/app/shared/types/leads.interface'

import {PersistanceService} from 'src/app/shared/services/persistance.service'
import {
  getOpenLeadsAction,
  getOpenLeadsSuccessAction,
  getOpenLeadsFailureAction
} from 'src/app/openLeads/store/actions/getOpenLeads.action'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class GetLeadsEffect {
  getOpenLeads = createEffect(() =>
    this.actions$.pipe(
      ofType(getOpenLeadsAction),
      switchMap(() => {
        console.log('GetLeadsEffect');
        const token = this.persistanceService.get('accessToken')

        if (!token) {
          return of(getOpenLeadsFailureAction())
        }

        return this.leadsService.getOpenLeads().pipe(

          map((data: LeadsInterface) => {
            console.log("success")
            return getOpenLeadsSuccessAction(data)
          }),

          catchError(() => {
            return of(getOpenLeadsFailureAction())
          })
        )
      })
    )
  )

  constructor(
    private actions$: Actions,
    private leadsService: LeadsService,
    private persistanceService: PersistanceService
  ) {}
}


// export class GetLeadsEffect {
//   constructor(private http: HttpClient) {
//     console.log("GetLeadsEffect")
//   }
//
//   getLeads$(): string {
//     return "hello"
//   }
//
// }
