<!--<nav class="navbar navbar-light">-->
<!--    <ul class="nav navbar-nav pull-xs-right">-->
<!--      <li class="nav-item">-->
<!--        <a [routerLink]="['/']" class="nav-link">-->
<!--          Home-->
<!--        </a>-->
<!--      </li>-->
<!--      <ng-container *ngIf="isLoggedIn$ | async">-->
<!--        <li class="nav-item">-->
<!--          <a [routerLink]="['/articles/new']" class="nav-link">-->
<!--            <i class="ion-compose"></i>-->
<!--            &nbsp; New Post-->
<!--          </a>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <a [routerLink]="['/settings']" class="nav-link">-->
<!--            <i class="ion-gear-a"></i>-->
<!--            &nbsp; Settings-->
<!--          </a>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <a-->
<!--            [routerLink]="['/profiles', (currentUser$ | async).username]"-->
<!--            class="nav-link"-->
<!--          >-->
<!--            <img class="user-pic" [src]="(currentUser$ | async).image" />-->
<!--            &nbsp; {{ (currentUser$ | async).username }}-->
<!--          </a>-->
<!--        </li>-->
<!--      </ng-container>-->
<!--      <ng-container *ngIf="isAnonymous$ | async">-->
<!--        <li class="nav-item">-->
<!--          <a [routerLink]="['/login']" class="nav-link">-->
<!--            Sign in-->
<!--          </a>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <a [routerLink]="['/register']" class="nav-link">-->
<!--            Sign up-->
<!--          </a>-->
<!--        </li>-->
<!--      </ng-container>-->
<!--    </ul>-->
<!--  -->
<!--</nav>-->
<nav class="navbar navbar-expand-lg navbar-light">
  <button class="show-sidebar" [ngClass]="{'open' : sidebarShowed}" type="button" (click)="showSidebar()">
    →
  </button>
  <ng-container *ngIf="isAnonymous$ | async">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  </ng-container>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto">
<!--      <ng-container *ngIf="isLoggedIn$ | async">-->
<!--        <li class="nav-item active">-->
<!--          <a [routerLink]="['/']" class="nav-link">-->
<!--            Home-->
<!--          </a>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <a-->
<!--            [routerLink]="['/profile']"-->
<!--            class="nav-link"-->
<!--          >-->
<!--            <img class="user-pic" [src]="(currentUser$ | async).image" />-->
<!--            &nbsp; {{ (currentUser$ | async).firstname }}-->
<!--          </a>-->
<!--        </li>-->
<!--      </ng-container>-->
      <ng-container *ngIf="isAnonymous$ | async">
        <li class="nav-item">
          <a [routerLink]="['/login']" class="nav-link">
            Sign in
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/register']" class="nav-link">
            Sign up
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</nav>
