import {Component, Inject, OnInit, Renderer2} from '@angular/core'
import {DOCUMENT} from '@angular/common'

@Component({
  selector: 'steps-form-calculator-cmp',
  templateUrl: 'steps-form-calculator.component.html'
})

export class StepsFormCalculatorComponent implements OnInit{

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2
  ) {

  }

  ngOnInit(){
    const time = Date.now();
    const renderer2 = this.renderer2;
    // const textScript = this.renderer2.createElement('script');
    // textScript.src = 'https://mortgages.ratepal.ca/api/allScripts.js';
    // this.renderer2.appendChild(this.document.body, textScript);
    //
    // const textScript1 = this.renderer2.createElement('script');
    // textScript1.src = 'https://mortgages.ratepal.ca/js/widget_steps/jquery-3.3.1.min.js';
    // this.renderer2.appendChild(this.document.body, textScript1);

    const textScript11 = this.renderer2.createElement('script');
    textScript11.src = 'https://mortgages.ratepal.ca/js/widget_steps/select2.full.min.js';

    const textScript12 = this.renderer2.createElement('script');
    textScript12.src = 'https://mortgages.ratepal.ca/js/widget_steps/jscolor.js';

    const textScript13 = this.renderer2.createElement('script');
    textScript13.src = 'https://mortgages.ratepal.ca/js/widget_steps/script_broker.js?v='+time;

    // const textScript2 = this.renderer2.createElement('script');
    // textScript2.src = 'https://mortgages.ratepal.ca/api/mortgage-rates-calculator/load_brokers.js';

    setTimeout(function(){
      renderer2.appendChild(this.document.body, textScript11);
    }, 0)

    setTimeout(function(){
      renderer2.appendChild(this.document.body, textScript12);
      renderer2.appendChild(this.document.body, textScript13);
      // renderer2.appendChild(this.document.body, textScript2);
    }, 500)

  }
}
