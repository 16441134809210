import {Component, Input, OnInit} from '@angular/core';

import {LeadService} from 'src/app/lead/services/lead.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'editable-input-cmp',
  templateUrl: 'editableInput.component.html',
  styleUrls: [ './editableInput.component.scss' ]
})

export class EditableInputComponent implements OnInit {
  @Input() public inputValue: string;
  @Input() public inputClass: string;
  @Input() public isNumber: boolean;
  @Input() public inputDisabled: boolean | '';
  @Input() public callbackFunction: (val: string, field: string) => void;
  @Input() public field: string;
  oldValue: string;
  isActive: '';
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  constructor() {

  }

  inputClick($event): void {
    this.inputClass += ' active';
    this.oldValue = $event.target.value;
  }

  inputChange($event): void {
    this.inputValue = $event.target.value;
  }

  inputFocus($event): void {
    if (this.isNumber) {
      $event.target.value = $event.target.value.replace('.00', '').replace(/[^0-9]/g, '');
    }
  }

  inputBlur($event): void {
    if (this.isNumber) {$event.target.value = this.formatter.format($event.target.value); }
  }

  clickOkFunction($event): void {
    this.inputClass = this.inputClass.replace('active', '');
    this.callbackFunction($event, this.field);
  }

  clickCancelFunction(): void {
    this.inputClass = this.inputClass.replace('active', '');
    this.inputValue = this.oldValue;
  }

  ngOnInit() {

  }

}
