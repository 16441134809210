import {createAction, props} from '@ngrx/store'

import {ActionTypes} from 'src/app/crm/store/actionTypes'
import { LeadsInterface } from 'src/app/shared/types/leads.interface'

export const getLeadsAction = createAction(
  ActionTypes.GET_LEADS
)

export const getLeadsSuccessAction = createAction(
  ActionTypes.GET_LEADS_SUCCESS,
  props<LeadsInterface>()
)

export const getLeadsFailureAction = createAction(
  ActionTypes.GET_LEADS_FAILURE
)
