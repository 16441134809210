import {createAction, props} from '@ngrx/store'

import {ActionTypes} from 'src/app/lead/store/actionTypes'
import {LeadInterface} from 'src/app/lead/types/lead.interface'

export const getLeadAction = createAction(
  ActionTypes.GET_LEAD
)

export const getLeadSuccessAction = createAction(
  ActionTypes.GET_LEAD_SUCCESS,
  props<LeadInterface>()
)

export const getLeadFailureAction = createAction(
  ActionTypes.GET_LEAD_FAILURE
)

export const updateLeadAction = createAction(
  ActionTypes.UPDATE_LEAD,
  props<{payload: { id: number, field: string, value: string}}>()

)

export const updateLeadSuccessAction = createAction(
  ActionTypes.UPDATE_LEAD_SUCCESS,
  props<LeadInterface>()
)

export const updateLeadFailureAction = createAction(
  ActionTypes.UPDATE_LEAD_FAILURE
)


