import {Component, Inject, OnInit, Renderer2} from '@angular/core'
import {DOCUMENT} from '@angular/common'

@Component({
  selector: 'mortgage-rates-calculator-cmp',
  templateUrl: 'mortgage-rates-calculator.component.html',
  styleUrls: ['./mortgage-rates-calculator.component.scss']
})

export class MortgageRatesComponent implements OnInit{
  public preloadingClass: string;
  multiple: string;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2
  ) {

  }

  ngOnInit(){
    const time = Date.now();
    let self = this;
    this.preloadingClass = "preloader";
    const renderer2 = this.renderer2;

    const srcScript = this.renderer2.createElement('script');
    srcScript.type = 'text/javascript';
    srcScript.text = `
      (function() {
        jscolor.installByClassName("jscolor")
      }());
    `;

    const textScript11 = this.renderer2.createElement('script');
    textScript11.src = 'https://mortgages.ratepal.ca/js/widget_table/select2.full.min.js';

    const textScript12 = this.renderer2.createElement('script');
    textScript12.src = 'https://mortgages.ratepal.ca/js/widget_table/jscolor.js';

    const textScript13 = this.renderer2.createElement('script');
    textScript13.src = 'https://mortgages.ratepal.ca/js/widget_table/script_broker.js?v='+time;

    // const textScript2 = this.renderer2.createElement('script');
    // textScript2.src = 'https://mortgages.ratepal.ca/api/mortgage-rates-calculator/load_brokers.js';

    setTimeout(function(){
      renderer2.appendChild(this.document.body, textScript11);
    }, 0)

    setTimeout(function(){
      renderer2.appendChild(this.document.body, textScript12);
      renderer2.appendChild(this.document.body, srcScript);
      renderer2.appendChild(this.document.body, textScript13);
      // renderer2.appendChild(this.document.body, textScript2);
    }, 500)

    setTimeout(function (){
      self.preloadingClass = "";
    }, 1000)

  }
}
