<div class="inputWrapper {{inputDisabled}}" >
  <textarea type="text" class="{{inputClass}}" value="{{inputValue}}" disabled="{{inputDisabled}}" (click)="inputClick($event)" (change)="inputChange($event)" (focus)="inputFocus($event)" (blur)="inputBlur($event)">
    {{inputValue}}
  </textarea>
  <span class="inputButtons">
    <span class="inputOk" (click)="clickOkFunction(inputValue)"></span>
    <span class="inputCancel" (click)="clickCancelFunction()"></span>
  </span>
</div>

<script>
  function auto_grow(element) {
    element.style.height = "5px";
    element.style.height = (element.scrollHeight)+"px";
  }
  auto_grow(jQuery('textarea'));
</script>
