import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterModule} from '@angular/router'

import {MessageBoxComponent} from 'src/app/shared/modules/messageBox/components/messageBox/messageBox.component'

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [MessageBoxComponent],
  exports: [MessageBoxComponent]
})
export class MessageBoxModule {}
