import {NgModule} from '@angular/core'
import {CommonModule, DatePipe, HashLocationStrategy, LocationStrategy} from '@angular/common'
import {BrowserModule} from '@angular/platform-browser'

import {StoreModule} from '@ngrx/store'
import {StoreDevtoolsModule} from '@ngrx/store-devtools'
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http'

import { Select2Module } from "ng-select2-component";
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {AuthModule} from 'src/app/auth/auth.module'
import {DashboardModule} from 'src/app/dashboard/dashboard.module'
import {ProfileModule} from 'src/app/profile/profile.module'
import {SettingsModule} from 'src/app/settings/settings.module'
import {CrmModule} from 'src/app/crm/crm.module'
import {OpenLeadsModule} from 'src/app/openLeads/openLeads.module'
import {LeadModule} from 'src/app/lead/lead.module'
import {SidebarModule} from 'src/app/shared/modules/sidebar/sidebar.module'
import {BanksSelectModule} from 'src/app/shared/modules/banksSelect/banksSelect.module'
import {LeadStatusSelectModule} from 'src/app/shared/modules/leadStatusSelect/leadStatusSelect.module'
import {EditableInputModule} from 'src/app/shared/modules/editableInput/editableInput.module'
import {EditableTextareaModule} from 'src/app/shared/modules/editableTextarea/editableTextarea.module'
import {WidgetsModule} from 'src/app/widgets/widgets.module'
import {environment} from 'src/environments/environment'
import {EffectsModule} from '@ngrx/effects'
import {TopBarModule} from 'src/app/shared/modules/topBar/topBar.module'
import {MessageBoxModule} from 'src/app/shared/modules/messageBox/messageBox.module'
import {PersistanceService} from './shared/services/persistance.service'
import {AuthInterceptor} from './shared/services/authinterceptor.service';
import {sendMessgaeService} from './shared/modules/messageBox/services/sendMessgae.service';
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { FormsModule } from '@angular/forms'

import {CanadianMortgageCalculatorModule} from 'src/app/calcs/canadian-mortgage-calculator/canadian-mortgage-calculator.module'
import {CurrentMortgageCalculatorModule} from 'src/app/calcs/current-mortgage-calculator/current-mortgage-calculator.module'
import {MortgageRatesCalculatorModule} from 'src/app/calcs/mortgage-rates-calculator/mortgage-rates-calculator.module'
import {RatesByLenderCalculatorModule} from 'src/app/calcs/rates-by-lender-calculator/rates-by-lender-calculator.module'
import { ProfileComponent } from './profile/components/profile/profile.component'
import { TopBarComponent } from './shared/modules/topBar/components/topBar/topBar.component'

import {DpDatePickerModule} from 'ng2-date-picker';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule,
    DashboardModule,
    ProfileModule,
    CrmModule,
    OpenLeadsModule,
    LeadModule,
    SidebarModule,
    BanksSelectModule,
    LeadStatusSelectModule,
    EditableInputModule,
    EditableTextareaModule,
    WidgetsModule,
    Select2Module,
    DateTimePickerModule,
    StoreModule.forRoot({}),
    FormsModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    EffectsModule.forRoot([]),
    TopBarModule,
    MessageBoxModule,
    StoreRouterConnectingModule.forRoot(),

    //cals
    CanadianMortgageCalculatorModule,
    CurrentMortgageCalculatorModule,
    MortgageRatesCalculatorModule,
    RatesByLenderCalculatorModule,

    DpDatePickerModule
  ],
  providers: [
    PersistanceService,
    sendMessgaeService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    OpenLeadsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
