import {createFeatureSelector, createSelector} from '@ngrx/store'
import {AppStateInterface} from 'src/app/shared/types/appState.interface'
import {LeadInterface} from '../types/lead.interface'

export const leadFeatureSelector = createFeatureSelector<
  AppStateInterface,
  LeadInterface
  >('lead')

export const getLeadSelector = createSelector(
  leadFeatureSelector,
  (leadState: LeadInterface) => {
    console.log('getLeadSelector')
    console.log(leadState)
    return leadState
  }
)

export const updateLeadSelector = createSelector(
  leadFeatureSelector,
  (leadState: LeadInterface) => {
    console.log('updateLeadSelector')
    console.log(leadState)
    return leadState
  }
)
