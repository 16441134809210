import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {map} from 'rxjs/operators'

import {RegisterRequestInterface} from 'src/app/auth/types/registerRequest.interface'
import {CurrentUserInterface} from 'src/app/shared/types/currentUser.interface'
import {environment} from 'src/environments/environment'
import {AuthResponseInterface} from 'src/app/auth/types/authResponse.interface'
import {LoginRequestInterface} from 'src/app/auth/types/loginRequest.interface'
import {PersistanceService} from 'src/app/shared/services/persistance.service'
import {CurrentUserInputInterface} from '../../shared/types/currentUserInput.interface'
import {GetCurrentUserInterface} from '../types/getCurrentUser.interface'

@Injectable()
export class AuthService {
  constructor(private http: HttpClient, private persistanceService: PersistanceService,) {}

  getUser(response: AuthResponseInterface): CurrentUserInterface {
    return response.user
  }

  register(data: RegisterRequestInterface): Observable<CurrentUserInterface> {
    const url = environment.apiUrl + '/create_user.php'
    return this.http
      .post<AuthResponseInterface>(url, data)
      .pipe(map(this.getUser))
  }


  login(data: LoginRequestInterface): Observable<CurrentUserInterface> {
    const url = environment.apiUrl + '/login.php'
    return this.http
      .post<AuthResponseInterface>(url, data)
      .pipe(map(this.getUser))
  }

  getCurrentUser(): Observable<CurrentUserInterface> {
    console.log('get_current_user')
    const url = environment.apiUrl + '/get_current_user.php'
    const data = {
      user : {
        email: this.persistanceService.get('email'),
        token: this.persistanceService.get('accessToken')
      }
    }
    // data.user.token = this.persistanceService.get('token')
    return this.http.post(url, data).pipe(map(this.getUser))
  }


  isAuth():Observable<any> {
    const url = environment.apiUrl + '/get_is_auth.php'
    const data = {
      user : {
        email: this.persistanceService.get('email'),
        token: this.persistanceService.get('accessToken')
      }
    }
    return this.http.post(url, data)
  }

  updateCurrentUser(data: CurrentUserInputInterface): Observable<CurrentUserInterface> {
    const url = environment.apiUrl + '/update_user.php'
    return this.http
      .post<AuthResponseInterface>(url, data)
      .pipe(map(this.getUser))
  }
}
