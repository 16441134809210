import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlansSelectComponent } from './plansSelect.components';

@NgModule({
  imports: [ CommonModule ],
  declarations: [ PlansSelectComponent ],
  exports: [ PlansSelectComponent ]
})

export class PlansSelectModule {}
