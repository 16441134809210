import { HttpClient, HttpParams } from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core'


@Component({
  selector: 'bank-types-select-cmp',
  templateUrl: 'bankTypesSelect.component.html'
})

export class BankTypesSelectComponent implements OnInit{
  @Input('selectID') selectID = '';
  public BankTypes: any

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit() {
    const formData = new FormData();
    formData.append('api', "getBankTypes");
    formData.append('json', "true");


    this.httpClient.post('https://mortgages.ratepal.ca/api/backend.php', formData).subscribe(data => this.BankTypes = data);
  }

}
